import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

const usePatientStates = defaultValue => {
  const [patientStates, setPatientStates] = useState(defaultValue)

  useEffect(() => {
    GET('patient-states').then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
        // TODO: quitar esto cuando el desarrollo backend esté listo
        setPatientStates([
          {
            id: 1,
            category: 'actitud',
            name1: 'Coherente',
            name2: 'Desorganizado',
            minimum: 1,
            maximum: 5,
            description1: 'Descripción uno',
            description2: 'Descripción dos',
          },
          {
            id: 2,
            category: 'actitud',
            name1: 'Rápido',
            name2: 'Lento',
            minimum: 1,
            maximum: 5,
            description1: 'Descripción uno',
            description2: 'Descripción dos',
          },
          {
            id: 3,
            category: 'actitud',
            name1: 'Inteligente',
            name2: 'Torpe',
            minimum: 1,
            maximum: 5,
            description1: 'Descripción uno',
            description2: 'Descripción dos',
          },
          {
            id: 4,
            category: 'actitud',
            name1: 'Concentrado',
            name2: 'Distraído',
            minimum: 1,
            maximum: 5,
            description1: 'Descripción uno',
            description2: 'Descripción dos',
          },
        ])
      } else if (res.body !== null) {
        setPatientStates(res.body)
      }
    })
  }, [])

  return [patientStates, setPatientStates]
}

export default usePatientStates
