import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

const useProblematics = defaultValue => {
  const [problematics, setProblematics] = useState(defaultValue)

  useEffect(() => {
    GET('problematics').then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.body !== null) {
        setProblematics(res.body)
      }
    })
  }, [])

  return [problematics, setProblematics]
}

export default useProblematics
