import React, { useState } from 'react'
import { toast } from 'react-toastify'

import './styles.scss'

import PUSH from '../../dao/push'
import GET from '../../dao/get'

// Form
// Pensado para tablas que sólo requieren el campo name.
// y que usan sólo el method PUT.
//
// @param uri de la API a donde se enviará la petición PUT y de donde se obtienen los datos con GET.
// @param fn es la función setState que se ejecuta si el backend retorna un mensaje de éxito.
function Form(props) {
  const [name, setName] = useState('')

  function submit() {
    PUSH('PUT', props.uri, { name }).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else {
        toast.info(res.body.message)
        GET(props.uri).then(res => {
          if (res.status >= 400) {
            toast.error(res.body.message)
          } else {
            props.fn(
              res.body.map(item => {
                return {
                  value: item.id,
                  label: item.name,
                }
              })
            )

            props.onClose()
          }
        })
      }
    })
  }

  return (
    <div className="form-container">
      <h2>{props.title}</h2>

      <input
        type="text"
        value={name}
        onChange={e => setName(e.target.value)}
        className="form-container__textfield"
      />

      <div className="buttons">
        <button className="btn" onClick={submit}>
          Enviar
        </button>
        <button className="btn" onClick={props.onClose}>
          Cancelar
        </button>
      </div>
    </div>
  )
}

export default Form
