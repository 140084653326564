const CivilStatus = [
  { value: 'casado', label: 'Casado' },
  { value: 'divorciado', label: 'Divorciado' },
  { value: 'soltero', label: 'Soltero' },
  { value: 'separado', label: 'Separado' },
  { value: 'unión libre', label: 'Unión libre' },
  { value: 'unión marital de hecho', label: 'Unión marital de hecho' },
  { value: 'viudo', label: 'Viudo' },
]

export default CivilStatus
