import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

export const useOrganigrams = defaultValue => {
  const [organigrams, setOrganigrams] = useState(defaultValue)

  useEffect(() => {
    GET('organigrams').then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.body !== null) {
        setOrganigrams(res.body)
      }
    })
  }, [])

  return [organigrams, setOrganigrams]
}

export default useOrganigrams
