import React, { useState, useEffect } from 'react'
import { TreeSelect } from 'antd'
import 'antd/es/tree-select/style/css'

export function OrganigramSelect ({
  organigram,
  onChange,
  value,
  placeholder,
  emptyText,
  canSelectAny,
  disabled,
  style
}) {
  const [selectData, setSelectData] = useState([])

  // prepara el organigrama en jerarquías
  useEffect(() => {
    if (!organigram || organigram.length === 0) {
      return
    }

    const parents = {}
    organigram.forEach(item => {
      let row = parents[item.parent_id]
      if (!row) row = []
      row.push({
        value: item.id,
        title: item.name
      })
      parents[item.parent_id] = row
    })

    const createDataChildren = key => {
      return parents[key].map(item => {
        const row = parents[item.value]
        if (row) {
          item.children = createDataChildren(item.value)
        }
        item.selectable = canSelectAny || key > 0
        return item
      })
    }

    const data = createDataChildren(0)
    if (emptyText) data.unshift({ value: 0, title: emptyText })
    setSelectData(data)
  }, [organigram])

  return (
    <TreeSelect
      showSearch
      disabled={disabled}
      style={style || { width: 'calc(100% - 30px)' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={placeholder}
      treeNodeFilterProp='title'
      treeData={selectData}
      value={value}
      onChange={onChange}
    />
  )
}
