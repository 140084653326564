import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

const useProcessResults = defaultValue => {
  const [processResults, setProcessResults] = useState(defaultValue)

  useEffect(() => {
    GET('process-results').then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.body !== null) {
        setProcessResults(
          res.body.map(item => {
            return {
              value: item.id,
              label: item.name,
            }
          })
        )
      }
    })
  }, [])

  return [processResults, setProcessResults]
}

export default useProcessResults
