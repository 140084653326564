import React, { useState, useEffect } from 'react'
import { TreeSelect } from 'antd'
import 'antd/es/tree-select/style/css'

export function DiagnosticSelect ({
  diagnostics,
  onChange,
  value,
  placeholder,
  emptyText,
  canSelectAny,
  catalogId
}) {
  const [selectData, setSelectData] = useState([])

  // prepara los diagnósticos para mostrar jerarquía
  useEffect(() => {
    if (!diagnostics || diagnostics.length === 0) {
      return
    }

    const catalogs = {}
    const parents = {}
    diagnostics.forEach(item => {
      if (catalogId && item.diagnostic_catalog_id !== catalogId) return
      let catalog = parents[item.diagnostic_catalog_id]
      if (!catalog) {
        catalogs[item.diagnostic_catalog_id] = {
          title: item.catalog,
          value: item.catalog,
          selectable: false
        }
        catalog = {}
      }

      let row = catalog[item.parent_id]
      if (!row) row = []
      row.push({
        value: item.id,
        title: item.name
      })
      catalog[item.parent_id] = row
      parents[item.diagnostic_catalog_id] = catalog
    })

    const createDataChildren = (catalogID, key) => {
      return parents[catalogID][key].map(item => {
        const row = parents[catalogID][item.value]
        if (row) {
          item.children = createDataChildren(catalogID, item.value)
        }
        item.selectable = canSelectAny || key > 0
        return item
      })
    }

    let data = []
    if (catalogId) {
      data = createDataChildren(catalogId, 0)
    } else {
      data = Object.keys(catalogs).map(catalogID => {
        const data = createDataChildren(catalogID, 0)
        const catalog = catalogs[catalogID]
        catalog.children = data
        return catalog
      })
    }

    if (emptyText) data.unshift({ value: 0, title: emptyText })
    setSelectData(data)
  }, [diagnostics])

  return (
    <TreeSelect
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={placeholder}
      treeNodeFilterProp='title'
      treeData={selectData}
      value={value}
      onChange={onChange}
    />
  )
}
