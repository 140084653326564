import React from 'react'
import Modal from 'react-modal'

import closeIcon from '../../images/close.svg'
import Radio from '../../components/Radio'

import './styles.scss'

export function CharacterCall ({
  isOpen,
  onClose,
  callCharacterizations,
  callCharacterizationID,
  setCallCharacterizationID
}) {
  Modal.setAppElement('.layout-container')

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      className='ModalCharacterCall'
      style={{
        overlay: { zIndex: 999 },
        content: {
          width: '700px',
          margin: '0 auto',
          backgroundColor: 'white',
          padding: '1rem'
        }
      }}
    >

      <h1>
        CARACTERIZACIÓN DE LA LLAMADA
        <span
          className='close-icon'
          onClick={onClose}
          style={{
            backgroundImage: `url("${closeIcon}")`
          }}
        />
      </h1>

      <div
        style={{
          margin: '0 1rem 0 4rem',
          display: 'flex',
          flexWrap: 'wrap',
          fontSize: '18px',
          textTransform: 'uppercase',
          justifyContent: 'space-around'
        }}
      >
        {callCharacterizations.map(cc => {
          return (
            <label
              key={cc.id}
              style={{
                width: '46%',
                display: 'flex',
                alignItems: 'center',
                margin: '0.5em'
              }}
            >
              <Radio
                value={cc.id}
                checked={cc.id === callCharacterizationID}
                onChange={e => {
                  setCallCharacterizationID(parseInt(e.target.value, 10))
                }}
                className='dark'
                containerclassname='size-32 inline-radio'
              />
              <div
                style={{
                  marginLeft: '0.5em',
                  fontSize: '18px'
                }}
              >
                {cc.name}
              </div>
            </label>
          )
        })}
      </div>

      <button
        style={{
          color: 'white',
          width: '180px',
          display: 'block',
          margin: '2rem auto',
          backgroundColor: '#1D1D1B',
          borderColor: '#707070',
          fontSize: '18px',
          lineHeight: 1.8,
          padding: 0,
          fontWeight: 700
        }}
        onClick={() => {
          onClose()
        }}
      >
        Cerrar
      </button>
    </Modal>
  )
}
