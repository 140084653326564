import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import { Link } from 'gatsby'
import { navigate as navigate2 } from '@reach/router'
import { Animated } from 'react-animated-css'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import queryString from 'query-string'
import moment from 'moment'

import arrowIcon from '../images/arrow-white.svg'
import uploadIcon from '../images/upload.svg'

import 'react-confirm-alert/src/react-confirm-alert.css'
import './session.scss'

import Layout from '../components/Layout'
import jwt, { hasPermission } from '../components/Auth'
import CivilStatus from '../dao/civil-status'
import LocationStatuses from '../dao/location-status'
import usePopulations from '../hooks/populations'
import useEps from '../hooks/eps'
import useEducationLevels from '../hooks/education-levels'
import useRanks from '../hooks/ranks'
import useSourceChannels from '../hooks/source-channels'
import useOrganigrams from '../hooks/organigrams'
import useReasons from '../hooks/reasons'
import useProblematics from '../hooks/problematics'
import useProcesses from '../hooks/processes'
import useProcessResults from '../hooks/process-results'
import PUSH from '../dao/push'
import ParameterForm from '../components/ParameterForm'
import OrganigramForm from '../components/ParameterForm/organigram'
import GET from '../dao/get'
import usePatientStates from '../hooks/patient-states'
import usePrecipitants from '../hooks/precipitants'
import useInvolvedPeople from '../hooks/involved-people'
import useStrategies from '../hooks/strategies'
import useResponseChannels from '../hooks/response-channels'
import useCombatExposure from '../hooks/combat_exposure'
import useCasic from '../hooks/casic'
import Radio from '../components/Radio'
import Checkbox from '../components/Checkbox'
import { OrganigramSelect } from '../components/OrganigramSelect'
import { RankSelect } from '../components/RankSelect'
import { ProblematicSelect } from '../components/ProblematicSelect'
import { DiagnosticSelect } from '../components/DiagnosticSelect'
import { getDiagnostics } from '../services/api/get-diagnostics'

import {
  getSessionSummaries,
  putSessionSummary,
  postSessionSummary
} from '../services/api/session-summaries'

import { getCalls, putDuration, putCall } from '../services/api/calls'
import { CharacterCall } from '../components/CharacterCall'
import { getCallCharacterizations } from '../services/api/call-characterization'
import { RemissionPopup } from '../components/RemissionPopup'
import { CreateRemissionPopup } from '../components/CreateRemissionPopup'
import { postRemissionRequest } from '../services/api/remission-requests'
import { getTodotasks } from '../services/api/todotasks'
import { getNextCallAspects } from '../services/api/next-call-aspects'
import { Context } from '../Context'
import { ConfirmModal } from '../components/ConfirmModal'

const SessionPage = props => {
  const { isAuth, setLocalLoginTime } = useContext(Context)

  const queryParams = queryString.parse(props.location.search)

  // usado sólo en frontend
  const [serviceTimeUnit, setServiceTimeUnit] = useState('days')
  const [difficultyTimeUnit, setDifficultyTimeUnit] = useState('days')
  const timeUnits = [
    { value: 'days', label: 'Días' },
    { value: 'months', label: 'Meses' },
    { value: 'years', label: 'Años' }
  ]
  const [showNotes, setShowNotes] = useState(false)
  const phases = [
    { value: 'seguimiento', label: 'Seguimiento' },
    { value: 'tratamiento', label: 'Tratamiento' },
    { value: 'evaluación', label: 'Evaluación' }
  ]
  const [openModal, setOpenModal] = useState(false)
  const [openRemission, setOpenRemission] = useState(false)
  const [openCreateRemission, setOpenCreateRemission] = useState(false)
  const [confirmModal, setConfirmModal] = useState({
    open: false,
    content: '',
    onClose: () => setConfirmModal({ ...confirmModal, open: false }),
    onYes: () => console.log('Dijo que sí')
  })

  // indica si se obtuvo la sesión anterior
  const [isFromBeforeSession, setIsFromBeforeSession] = useState(
    queryParams.call_id !== undefined
  )

  // datasets
  const [populations] = usePopulations([])
  const [eps] = useEps([])
  const [educationLevels] = useEducationLevels([])
  const [ranks] = useRanks([])
  const [sourceChannels] = useSourceChannels([])
  const [organigrams, setOrganigrams] = useOrganigrams([])
  const [reasons] = useReasons([])
  const [problematics] = useProblematics([])
  const [processes] = useProcesses([])
  const [processResults] = useProcessResults([])
  const [patientStates] = usePatientStates([])
  const [precipitants, setPrecipitants] = usePrecipitants([])
  const [involvedPeople] = useInvolvedPeople([])
  const [strategies] = useStrategies([])
  const [responseChannels] = useResponseChannels([])
  const [combatExposure] = useCombatExposure([])
  const [casic] = useCasic([])
  const [diagnostics, setDiagnostics] = useState([])
  const [callCharacterizations, setCallCharacterizations] = useState([])
  const [todotasks, setTodotasks] = useState([])
  const [nextCallAspects, setNextCallAspects] = useState([])

  // campos de patients
  const [patientID, setPatientID] = useState(0)
  const [patient, setPatient] = useState({
    birthday: '',
    civil_status: '',
    civil_status_date: '',
    commander: '',
    contingent: '',
    created_at: '',
    document_number: '',
    education_level_id: 0,
    emergency_contacts: '',
    eps_id: 0,
    gender: 0,
    id: 0,
    lastname: '',
    location_details: '',
    location_status: '',
    name: '',
    organigram_id: 0,
    population_id: 0,
    rank_id: 0,
    service_time: 0.00,
    source_channel_id: 0,
    organigram_details: ' '
  })

  const [sessionID, setSessionID] = useState(0)
  const [session, setSession] = useState({
    patient_id: patient.id,
    call_id: parseInt(queryParams.call_id) || 0,
    is_draft: true,
    organigram_id: 0,
    organigram_details: ' ',
    difficulty_time: 0,
    familiar_area: '',
    work_area: '',
    academic_area: '',
    affective_area: '',
    social_area: '',
    personal_area: '',
    evaluation_evolution: '',
    intervention_evolution: '',
    session_development: '',
    problematic_id: 0,
    problematic_description: '',
    diagnostic_description: '',
    process_id: 0,
    process_result_id: 0,
    next_call_date: '',
    task_details: '',
    casic_priority: 0,
    casic_cognitive: '',
    casic_comments: '',
    familiar_area_check: 0,
    work_area_check: 0,
    academic_area_check: 0,
    affective_area_check: 0,
    social_area_check: 0,
    personal_area_check: 0
  })

  const [callPhone, setCallPhone] = useState('')
  const [callDuration, setCallDuration] = useState(0)
  const [callCharacterizationID, setCallCharacterizationID] = useState(0)
  const [notes, setNotes] = useState('')

  const [sessionSummary, setSessionSummary] = useState({})

  const [patientState, setPatientState] = useState([])
  const [selectedReasons, setSelectedReasons] = useState([])
  const [selectedTodotasks, setSelectedTodotasks] = useState([])
  const [selectedNextCallAspects, setSelectedNextCallAspects] = useState([])
  const [selectedPrecipitants, setSelectedPrecipitants] = useState([])
  const [selectedInvolvedPeople, setSelectedInvolvedPeople] = useState([])
  const [selectedStrategies, setSelectedStrategies] = useState([])
  const [selectedResponseChannels, setSelectedResponseChannels] = useState([])
  const [selectedCombatExposure, setSelectedCombatExposure] = useState([])
  const [selectedCasic, setSelectedCasic] = useState([])
  const [selectedDSM, setSelectedDSM] = useState(0)
  const [selectedCIE, setSelectedCIE] = useState(0)
  const [remitting, setRemitting] = useState() // si tiene solicitud remisión
  const [remitted, setRemitted] = useState() // si tiene remisión

  // get session obtiene del backend la sesión
  useEffect(() => {
    // query string parameters
    let query = ''

    if (isFromBeforeSession) {
      if (!patientID) return
      query = `?patient_id=${patientID}&page=1&per_page=1&order_by=created_at desc`
    } else {
      if (!sessionID) return
      query = `?id=${sessionID}`
    }

    // carga llamada si no hay sesión
    if (!sessionID && session.call_id) {
      getCallDuration(session.call_id)
    }

    // si ya existen ambas no es necesario volver a hacer la petición al backend
    if (sessionID && patientID && session.call_id) {
      return
    }

    GET('sessions', query).then(res => {
      if (res.body && res.body.length > 0) {
        const sess = res.body[0]

        if (sess.difficulty_time > 360) {
          sess.difficulty_time = Math.floor(sess.difficulty_time / 360)
          setDifficultyTimeUnit('years')
        } else if (sess.difficulty_time > 30) {
          sess.difficulty_time = Math.floor(sess.difficulty_time / 30)
          setDifficultyTimeUnit('months')
        }

        // si consultó la sesión anterior elimina campos
        if (isFromBeforeSession) {
          sess.process_id = 0
          sess.process_result_id = 0
          sess.session_development = ''
          sess.diagnostic_description = ''
          sess.problematic_id = 0
          sess.problematic_description = ''
          sess.next_call_date = ''
          sess.task_details = ''
          sess.call_id = parseInt(queryParams.call_id)
          sess.casic_priority = 0
        } else {
          setRemitting(sess.has_remission_request)
          setRemitted(sess.has_remission)
          getCallDuration(sess.call_id)
        }

        // usa el ID de la sesión anterior para traer datos de tablas foráneas y pivotes
        setSessionID(sess.id)

        if (isFromBeforeSession) {
          // la sesión nueva debe ser borrador
          sess.is_draft = true
          // quita el ID de la sesión actual para que se cree una al guardar
          sess.id = 0
        }

        setSession(sess)
        setPatientID(sess.patient_id)
      }
    })

    if (!sessionID) {
      return
    }

    // obtiene del pivote el diagnóstico
    GET(
      'pivots',
    `?action=diagnostic_catalog_item_session&session_id=${sessionID}`
    ).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else {
        if (res.status === 200) {
          let temp = res.body.find(
            item => item.diagnostic_catalog_item_id < 173
          )
          if (temp) setSelectedDSM(temp.diagnostic_catalog_item_id)

          temp = res.body.find(item => item.diagnostic_catalog_item_id >= 173)
          if (temp) setSelectedCIE(temp.diagnostic_catalog_item_id)
        }
      }
    })

      // obtiene del pivote los estados del paciente
      .then(() => {
        return GET('pivots', `?action=patient_state_session&session_id=${sessionID}`).then(
          res => {
            if (res.status >= 400) {
              toast.error(res.body.message)
            } else if (res.status === 200) {
              const states = res.body.map(item => {
                return {
                  during_call: parseInt(item.during_call, 10),
                  patient_state_id: parseInt(item.patient_state_id, 10),
                  value: parseInt(item.value, 10)
                }
              })

              if (states.length > 0) {
                setPatientState(states)
              }
            }
          }
        )
      })

      // obtiene del pivote los motivos de consulta
      .then(() => {
        return GET('pivots', `?action=reason_session&session_id=${sessionID}`).then(
          res => {
            if (res.status >= 400) {
              toast.error(res.body.message)
            } else {
              if (res.status === 200) {
                const ids = []
                res.body.forEach(item => {
                  ids.push(parseInt(item.reason_id))
                })
                if (ids.length > 0) {
                  setSelectedReasons(ids)
                }
              }
            }
          }
        )
      })

      // obtiene del pivote las tareas a realizar en la próxima llamada
      .then(() => {
        return GET('pivots', `?action=session_todo_task&session_id=${sessionID}`).then(
          res => {
            if (res.status >= 400) {
              toast.error(res.body.message)
            } else {
              if (res.status === 200) {
                const ids = []
                res.body.forEach(item => {
                  ids.push(parseInt(item.todo_task_id))
                })
                if (ids.length > 0) {
                  setSelectedTodotasks(ids)
                }
              }
            }
          }
        )
      })

      // obtiene del pivote los aspectos a evaluar en la próxima llamada
      .then(() => {
        return GET('pivots', `?action=next_call_aspect_session&session_id=${sessionID}`).then(
          res => {
            if (res.status >= 400) {
              toast.error(res.body.message)
            } else {
              if (res.status === 200) {
                const ids = []
                res.body.forEach(item => {
                  ids.push(parseInt(item.next_call_aspect_id))
                })
                if (ids.length > 0) {
                  setSelectedNextCallAspects(ids)
                }
              }
            }
          }
        )
      })

      // obtiene del pivote las escalas de exposición al combate
      .then(() => {
        return GET(
          'pivots',
          `?action=combat_exposure_session&session_id=${sessionID}`
        ).then(res => {
          if (res.status >= 400) {
            toast.error(res.body.message)
          } else {
            if (res.status === 200) {
              const ces = []
              res.body.forEach(item => {
                ces.push({
                  id: parseInt(item.combat_exposure_id, 10),
                  scale: parseInt(item.scale)
                })
              })
              if (ces.length > 0) {
                setSelectedCombatExposure(ces)
              }
            }
          }
        })
      })

      // obtiene del pivote los precipitantes
      .then(() => {
        return GET('pivots', `?action=precipitant_session&session_id=${sessionID}`).then(
          res => {
            if (res.status >= 400) {
              toast.error(res.body.message)
            } else {
              if (res.status === 200) {
                const ids = []
                res.body.forEach(item => {
                  ids.push(parseInt(item.precipitant_id))
                })
                if (ids.length > 0) {
                  setSelectedPrecipitants(ids)
                }
              }
            }
          }
        )
      })

      // obtiene del pivote las personas implicadas
      .then(() => {
        return GET(
          'pivots',
          `?action=involved_person_session&session_id=${sessionID}`
        ).then(res => {
          if (res.status >= 400) {
            toast.error(res.body.message)
          } else {
            if (res.status === 200) {
              const ids = []
              res.body.forEach(item => {
                ids.push(parseInt(item.involved_person_id))
              })
              if (ids.length > 0) {
                setSelectedInvolvedPeople(ids)
              }
            }
          }
        })
      })

      // obtiene del pivote las estrategias empleadas anteriormente
      .then(() => {
        return GET('pivots', `?action=strategy_session&session_id=${sessionID}`).then(
          res => {
            if (res.status >= 400) {
              toast.error(res.body.message)
            } else {
              if (res.status === 200) {
                const ids = []
                res.body.forEach(item => {
                  ids.push(parseInt(item.strategy_id))
                })
                if (ids.length > 0) {
                  setSelectedStrategies(ids)
                }
              }
            }
          }
        )
      })

      // obtiene del pivote los canales de respuesta
      .then(() => {
        return GET(
          'pivots',
          `?action=response_channel_session&session_id=${sessionID}`
        ).then(res => {
          if (res.status >= 400) {
            toast.error(res.body.message)
          } else {
            if (res.status === 200) {
              const ids = []
              res.body.forEach(item => {
                ids.push(parseInt(item.response_channel_id))
              })
              if (ids.length > 0) {
                setSelectedResponseChannels(ids)
              }
            }
          }
        })
      })

      // obtiene del pivote los casic
      .then(() => {
        return GET('pivots', `?action=casic_session&session_id=${sessionID}`).then(res => {
          if (res.status >= 400) {
            toast.error(res.body.message)
          } else {
            if (res.status === 200) {
              const ids = []
              res.body.forEach(item => {
                ids.push(parseInt(item.casic_id))
              })
              if (ids.length > 0) {
                setSelectedCasic(ids)
              }
            }
          }
        })
      })
  }, [sessionID, patientID])

  // agrega patient_id a la URL cada vez que patientID cambia
  useEffect(() => {
    if (queryParams.patient_id && !patientID) {
      setPatientID(parseInt(queryParams.patient_id, 10))
    } else if (!queryParams.patient_id && patientID) {
      queryParams.patient_id = patientID
      navigate2(`${props.path}?${queryString.stringify(queryParams)}`, {
        replace: true
      })
    }
  }, [patientID])

  // get patient obtiene del backend los datos del paciente patientID
  useEffect(() => {
    if (patientID) {
      GET('patients', `?id=${patientID}`).then(res => {
        if (res.body.length > 0) {
          const pat = res.body[0]

          if (pat.service_time > 360) {
            pat.service_time = pat.service_time / 360
            setServiceTimeUnit('years')
          } else if (pat.service_time > 30) {
            pat.service_time = pat.service_time / 30
            setServiceTimeUnit('months')
          }

          setPatient(pat)
        } else {
          toast.error(`No se encontró el paciente #${patientID}`)
        }
      })
    }
  }, [patientID])

  // get session_summary
  useEffect(() => {
    if (!sessionID || isFromBeforeSession) return

    getSessionSummaries({ session_id: sessionID })
      .then(res => {
        if (res.data.length > 0) {
          setSessionSummary(res.data[0])
        }
      })
      .catch(err => {
        console.log('getSessionSummaries', err)
      })
  }, [sessionID])

  // agrega el session_id a la URL de la página actual en caso de no estar ya
  useEffect(() => {
    if (queryParams.session_id && !session.id) {
      setSessionID(parseInt(queryParams.session_id, 10))
      setIsFromBeforeSession(false)
    } else if (!queryParams.session_id && session.id && !isFromBeforeSession) {
      queryParams.session_id = session.id
      delete queryParams.call_id
      navigate2(`${props.path}?${queryString.stringify(queryParams)}`, {
        replace: true
      })
    }
  }, [sessionID, isFromBeforeSession])

  // obtiene del backend los datos de las tablas parameters
  useEffect(() => {
    // obtiene del backend los diagnósticos
    getDiagnostics()
      .then(res => setDiagnostics(res.data))
      .catch(err => console.error(err.response))

    // obtiene del backend los registros de todo_tasks
    getTodotasks()
      .then(res => setTodotasks(res.data.map(i => {
        return {
          value: i.id,
          label: i.name
        }
      })))
      .catch(err => console.error(err.response))

    // obtiene del backend los registros de next_call_aspects
    getNextCallAspects()
      .then(res => setNextCallAspects(res.data.map(i => {
        return {
          value: i.id,
          label: i.name
        }
      })))
      .catch(err => console.error(err.response))

    // obtiene del backend las caracterizaciones de llamada
    getCallCharacterizations()
      .then(res => setCallCharacterizations(res.data))
      .catch(err => console.dir(err))
  }, [])

  // envía los estados del paciente
  function pushPatientStates () {
    setLocalLoginTime(0)
    if (!session.id || patientState.length === 0) return

    const states = [...patientState]

    // asigna a cada estado session_id
    states.forEach(item => {
      item.session_id = session.id
    })

    PUSH(
      'PUT',
      'pivots',
      { values: states },
      '?action=patient_state_session'
    ).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      }
    })
  }

  // envía los motivos de consulta
  function pushReasons () {
    setLocalLoginTime(0)
    if (!session.id || selectedReasons.length === 0) return

    const data = selectedReasons.map(reasonID => {
      return {
        session_id: session.id,
        reason_id: reasonID
      }
    })

    PUSH('PUT', 'pivots', { values: data }, '?action=reason_session').then(
      res => {
        if (res.status >= 400) {
          toast.error(res.body.message)
        }
      }
    )
  }

  // envía las tareas a realizar en la próxima llamada
  function pushTodotasks () {
    setLocalLoginTime(0)
    if (!session.id || selectedTodotasks.length === 0) return

    const data = selectedTodotasks.map(id => {
      return {
        session_id: session.id,
        todo_task_id: id
      }
    })

    PUSH('PUT', 'pivots', { values: data }, '?action=session_todo_task').then(
      res => {
        if (res.status >= 400) {
          toast.error(res.body.message)
        }
      }
    )
  }

  // envía los aspectos a evaluar en la próxima llamada
  function pushNextCallAspects () {
    setLocalLoginTime(0)
    if (!session.id || selectedNextCallAspects.length === 0) return

    const data = selectedNextCallAspects.map(id => {
      return {
        session_id: session.id,
        next_call_aspect_id: id
      }
    })

    PUSH('PUT', 'pivots', { values: data }, '?action=next_call_aspect_session').then(
      res => {
        if (res.status >= 400) {
          toast.error(res.body.message)
        }
      }
    )
  }

  // envía los precipitantes
  function pushPrecipitants () {
    setLocalLoginTime(0)
    if (!session.id || selectedPrecipitants.length === 0) return

    const data = selectedPrecipitants.map(precipitantID => {
      return {
        session_id: session.id,
        precipitant_id: precipitantID
      }
    })

    PUSH('PUT', 'pivots', { values: data }, '?action=precipitant_session').then(
      res => {
        if (res.status >= 400) {
          toast.error(res.body.message)
        }
      }
    )
  }

  // envía las personas implicadas
  function pushInvolvedPeople () {
    setLocalLoginTime(0)
    if (!session.id || selectedInvolvedPeople.length === 0) return

    const data = selectedInvolvedPeople.map(ID => {
      return {
        session_id: session.id,
        involved_person_id: ID
      }
    })

    PUSH(
      'PUT',
      'pivots',
      { values: data },
      '?action=involved_person_session'
    ).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      }
    })
  }

  // envía las estrategias empleadas anteriormente
  function pushStrategies () {
    setLocalLoginTime(0)
    if (!session.id || selectedStrategies.length === 0) return

    const data = selectedStrategies.map(ID => {
      return {
        session_id: session.id,
        strategy_id: ID
      }
    })

    PUSH('PUT', 'pivots', { values: data }, '?action=strategy_session').then(
      res => {
        if (res.status >= 400) {
          toast.error(res.body.message)
        }
      }
    )
  }

  // envía los canales de respuesta
  function pushResponseChannels () {
    setLocalLoginTime(0)
    if (!session.id || selectedResponseChannels.length === 0) return

    const data = selectedResponseChannels.map(ID => {
      return {
        session_id: session.id,
        response_channel_id: ID
      }
    })

    PUSH(
      'PUT',
      'pivots',
      { values: data },
      '?action=response_channel_session'
    ).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      }
    })
  }

  // envía los casic
  function pushCasic () {
    setLocalLoginTime(0)
    if (!session.id || selectedCasic.length === 0) return

    const data = selectedCasic.map(ID => {
      return {
        session_id: session.id,
        casic_id: ID
      }
    })

    PUSH('PUT', 'pivots', { values: data }, '?action=casic_session').then(
      res => {
        if (res.status >= 400) {
          toast.error(res.body.message)
        }
      }
    )
  }

  // envía las escalas de exposición al combate
  function pushCombatExposure () {
    setLocalLoginTime(0)
    if (!session.id || selectedCombatExposure.length === 0) return

    const data = selectedCombatExposure.map(sce => {
      return {
        session_id: session.id,
        combat_exposure_id: sce.id,
        scale: sce.scale
      }
    })

    PUSH(
      'PUT',
      'pivots',
      { values: data },
      '?action=combat_exposure_session'
    ).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      }
    })
  }

  // envía el diagnóstico DSM y CIE
  function pushDiagnostic () {
    setLocalLoginTime(0)
    if (!session.id || (!selectedDSM && !selectedCIE)) return

    const data = []
    if (selectedDSM) {
      data.push({
        session_id: session.id,
        diagnostic_catalog_item_id: selectedDSM
      })
    }
    if (selectedCIE) {
      data.push({
        session_id: session.id,
        diagnostic_catalog_item_id: selectedCIE
      })
    }

    PUSH(
      'PUT',
      'pivots',
      { values: data },
      '?action=diagnostic_catalog_item_session'
    ).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      }
    })
  }

  // envía al backend la caracterización de la llamada
  useEffect(() => {
    setLocalLoginTime(0)
    if (!callCharacterizationID || !session.is_draft || !openModal) return

    putCall({
      id: session.call_id,
      call_characterization_id: callCharacterizationID,
      duration: callDuration,
      notes: notes,
      patient_id: patientID
    })
      .then(res => {
        if (res.status === 200) {
          toast.success('Caracterización guardada')
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [callCharacterizationID])

  // actualiza la duración de la llamada en frontend
  useEffect(() => {
    if (!session.is_draft || !session.call_id || callCharacterizationID) return

    const interval = setInterval(() => {
      setCallDuration(callDuration => callDuration + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [callDuration, callCharacterizationID, session])

  // abre dialogo de remisión
  useEffect(() => {
    // si falta alguno de los 3 campos, no hace nada
    if (!session.id || remitting === undefined || remitted === undefined) return

    // si es borrador no se crea remisión
    if (session.is_draft) return

    setOpenCreateRemission(remitting && !remitted)
  }, [session, remitting, remitted])

  // obtiene del backend el registro de calls
  function getCallDuration (callID) {
    getCalls({ id: callID })
      .then(res => {
        if (res.status === 200) {
          let duration = res.data[0].duration
          // calcula la duración desde que se crea la llamada
          if (!duration) {
            const now = moment().format('X') // hora actual
            // hora de creación
            const createdAt = moment
              .utc(res.data[0].created_at)
              .utcOffset('-05:00')
              .format('X') // timestamp
            // diferencia en segundos
            duration = now - createdAt
          }
          setCallDuration(duration)
          setCallCharacterizationID(res.data[0].call_characterization_id)

          if (!notes) {
            setNotes(res.data[0].notes)
          }

          setCallPhone(res.data[0].phone)
        }
      })
      .catch(() => {
        toast.error(
          `No fue posible obtener la llamada ${callID} para calcular la duración`
        )
        setCallDuration(0)
      })
  }

  // envía la información de todos los pivotes
  function pushPivots () {
    setLocalLoginTime(0)
    if (!session.id || !session.is_draft) return

    // estados
    pushPatientStates()

    // motivos de consulta
    pushReasons()

    // tareas a realizar en la próxima llamada
    pushTodotasks()

    // aspectos a evaluar en la próxima llamada
    pushNextCallAspects()

    // precipitantes
    pushPrecipitants()

    // personas implicadas
    pushInvolvedPeople()

    // estrategias empleadas anteriormente
    pushStrategies()

    // canales de respuesta predominantes
    pushResponseChannels()

    // casics
    pushCasic()

    // escalas de exposición al combate
    pushCombatExposure()

    // impresión diagnóstica (catálogos)
    pushDiagnostic()
  }

  // envía la duración de la llamada
  function pushCallDuration () {
    setLocalLoginTime(0)
    if (!callCharacterizationID || !callDuration) return

    putDuration(session.call_id, callDuration).catch(error => {
      console.log(error)
      toast.error('No fue posible guardar la duración de la llamada')
    })
  }

  // envía patient al backend
  function pushPatient () {
    setLocalLoginTime(0)
    if (session.id && !session.is_draft) return

    if (!patient.name) {
      toast.error('El nombre del paciente es obligatorio')
      return
    }

    // convierte a días service_time
    const p = { ...patient }
    if (serviceTimeUnit === 'months') {
      p.service_time = Math.floor(p.service_time * 30)
    } else if (serviceTimeUnit === 'years') {
      p.service_time = Math.floor(p.service_time * 365)
    }

    delete p.created_at
    delete p.updated_at

    PUSH(p.id ? 'PUT' : 'POST', 'patients', p).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.status === 201) {
        setPatient({ ...patient, id: res.body.id })
        toast.success('Paciente creado')

        if (!queryParams.patient_id) {
          setPatientID(res.body.id)
        }
        pushNotes()
      } else {
        toast.success('Paciente actualizado')
        pushNotes()
      }
    })
  }

  // verifica que todos los campos obligatorios estén llenos antes de terminar
  // la sesión
  function filledFields () {
    // valida el campo como se enteró de la línea
    if (!patient.source_channel_id) {
      toast.error('Debes indicar cómo se enteró de la línea')
      return false
    }

    // valida condición inicio
    if (patientState.length > 0) {
      for (let i = 0; i < patientStates.length; i++) {
        if (patientStates[i].category === 'general_final') {
          continue
        }

        const filledState = patientState.filter(
          item =>
            item.patient_state_id === patientStates[i].id &&
            item.during_call === 1 &&
            item.value > 0
        )

        if (filledState.length === 0) {
          toast.error(`No has indicado un valor para la CONDICION INICIO: ${patientStates[i].name1} - ${patientStates[i].name2}`)
          return false
        }
      }
    } else {
      toast.error('No has llenado el estado del paciente')
      return false
    }

    // valida condición final
    if (patientState.length > 0) {
      for (let i = 0; i < patientStates.length; i++) {
        const filledState = patientState.filter(
          item =>
            item.patient_state_id === patientStates[i].id &&
            item.during_call === 0 &&
            item.value > 0
        )

        if (filledState.length === 0) {
          toast.error(`No has indicado un valor para la CONDICIÓN FINAL: ${patientStates[i].name1} - ${patientStates[i].name2}`)
          return false
        }
      }
    } else {
      toast.error('No has llenado el estado del paciente')
      return false
    }

    // valida motivos de consulta
    if (selectedReasons.length === 0) {
      toast.error('No has indicado el motivo de consulta')
      return false
    }

    // valida precipitante
    if (selectedPrecipitants.length === 0) {
      toast.error('No has indicado el precipitante')
      return false
    }

    // valida tiempo con la dificultad
    if (session.difficulty_time === 0) {
      toast.error('No has indicado el tiempo con la dificultad')
      return false
    }

    // valida columna impresión diagnóstica
    if (!selectedCIE) {
      toast.error('No has indicado la impresión diagnóstica del catálogo CIE 10')
      return false
    }
    if (!selectedDSM) {
      toast.error('No has indicado la impresión diagnóstica del catálogo DSM V')
      return false
    }
    if (!session.problematic_id) {
      toast.error('No has indicado la problemática en la impresión diagnóstica')
      return false
    }

    // valida el campo procedimiento
    if (!session.process_id) {
      toast.error('No has seleccionado el procedimiento')
      return false
    }

    if (!session.process_result_id) {
      toast.error('Nos has seleccionado el resultado del procedimiento')
      return false
    }

    // TODO: validar columna seguimiento?

    // valida la fase
    if (!sessionSummary.phase) {
      toast.error('No has indicado la fase en el resumen del caso')
      return false
    }

    return true
  }

  // lanza un modal de confirmación antes de crear la sesión
  function confirmPushSession (isDraft) {
    setLocalLoginTime(0)
    if (!session.id) {
      setConfirmModal({
        ...confirmModal,
        open: true,
        onYes: () => {
          pushSession(isDraft)
          setConfirmModal({ ...confirmModal, open: false })
        },
        content: <div className='text-center'>¿Realmente quieres crear una sesión con esta llamada?</div>
      })

      return
    }

    pushSession(isDraft)
  }

  // envía la información de la tabla sessions
  function pushSession (isDraft) {
    setLocalLoginTime(0)
    if (!isDraft && !filledFields()) {
      toast.warning('Para terminar la sesión todos los campos deben estar llenos')
      return
    }

    pushNotes()

    // guarda los datos del paciente
    pushPatient()

    // si no es borrador no guarda los datos de sesión
    if (!session.is_draft) return

    const s = { ...session }
    s.patient_id = patientID
    s.author = jwt().payload.user_id
    s.organigram_id = patient.organigram_id
    s.organigram_details = patient.organigram_details
    s.is_draft = isDraft

    // convierte a días difficulty_time
    if (difficultyTimeUnit === 'months') {
      s.difficulty_time *= 30
    } else if (difficultyTimeUnit === 'years') {
      s.difficulty_time *= 365
    }

    // quita los campos que no se pueden enviar en PUT
    if (s.id) {
      delete s.created_at
      delete s.updated_at
      delete s.author
      delete s.patient_id
      delete s.has_remission_request
      delete s.has_remission
    }

    // envia los datos al backend
    PUSH(s.id ? 'PUT' : 'POST', 'sessions', s)
      .then(res => {
        if (res.status >= 400) {
          toast.error(res.body.message)
        } else if (res.status === 201) {
          setSession({ ...session, id: res.body.id })
          toast.success('Sesión creada')
        } else if (res.status === 200) {
          toast.success('Sesión guardada')
          if (!isDraft) {
            setSession({ ...session, is_draft: isDraft })
          }
        }
        setIsFromBeforeSession(false)
        setSessionID(res.body.id)
      })
      // guarda session_summary, pivotes y duración de la llamada
      .then(() => {
        if (!s.id) {
          toast.warning(
            'Usa el botón GUARDAR nuevamente porque las notas no se enviaron.'
          )
          return
        }

        // guarda el resumen de la sesión
        pushSessionSummary(true)

        // envía la duración de la llamada
        pushCallDuration()

        // guarda los datos de las tablas pivote
        pushPivots()
      })
      .catch(error => {
        console.log(error)
        toast.error('No fue posible guardar los cambios')
      })
  }

  // envía al backend las notas
  function pushNotes () {
    setLocalLoginTime(0)
    // guarda datos y novedades
    putCall({
      id: session.call_id,
      notes: notes
    }).catch(err => {
      console.dir(err)
      toast.error(`${err}`)
    })
  }

  // envía la información de la tabla session_summaries
  function pushSessionSummary (force = false) {
    setLocalLoginTime(0)
    if (
      !force &&
      (!session.id ||
        !session.is_draft ||
        Object.keys(sessionSummary).length === 0 ||
        !sessionSummary.phase)
    ) {
      return
    }

    const data = { ...sessionSummary }
    delete data.updated_at
    delete data.created_at

    let pushRequest = null
    if (sessionSummary.id) {
      delete data.id
      pushRequest = putSessionSummary({
        ...data,
        phase: sessionSummary.phase ? sessionSummary.phase.value : '',
        session_id: session.id
      })
    } else {
      pushRequest = postSessionSummary({
        ...data,
        phase: sessionSummary.phase ? sessionSummary.phase.value : '',
        session_id: session.id
      })
    }

    pushRequest
      .then(res => {
        if (res.status === 201) {
          setSessionSummary({
            ...sessionSummary,
            id: res.data.id
          })
        }
      })
      .catch(err => {
        console.log('pushSessionSummary', err)
      })
  }

  // manejador de los cambios en los inputs
  function handleFieldChange (state, event) {
    setLocalLoginTime(0)
    if (!session.is_draft) return

    const target = event.target
    const name = target.name

    let value = null

    if (['checkbox', 'radio'].includes(target.type)) {
      if (target.checked) {
        value = parseInt(target.value)
      }
    } else if (target.type === 'number') {
      if (target.value) {
        if (target.attributes.numberType && target.attributes.numberType.value === 'decimal') {
          value = parseFloat(target.value)
        } else {
          value = parseInt(target.value)
        }
      }
    } else {
      value = target.value
    }

    let obj = {}
    if (state === 'patient') {
      obj = Object.assign(obj, patient)
      obj[name] = value
      setPatient(obj)
    } else if (state === 'session') {
      obj = Object.assign(obj, session)
      obj[name] = value
      setSession(obj)
    } else if (state === 'session_summary') {
      obj = Object.assign(obj, sessionSummary)
      obj[name] = value
      setSessionSummary(obj)
    }
  }

  // maneja los cambios en los radios de estado del paciente
  function handlePatientStateChange (patState, e) {
    setLocalLoginTime(0)
    if (!session.is_draft) return

    const pats = [...patientState]

    const pIndex = pats.findIndex(item => {
      return (
        item.patient_state_id === patState.patient_state_id &&
        item.during_call === patState.during_call
      )
    })

    if (e.target.checked) {
      // si aún no está en patientState
      if (pIndex === -1) {
        // lo agrega a patientState
        pats.push(patState)
      } else {
        pats[pIndex] = patState
      }
    } else {
      // si está en patientState
      if (pIndex !== -1) {
        // lo elimina de patientState
        pats.splice(pIndex, 1)
      }
    }

    setPatientState(pats)
  }

  // manejador de los cambios de los selects
  function handleSelectChange (state, name, option) {
    setLocalLoginTime(0)
    if (!session.is_draft) return

    let obj = {}
    if (state === 'patient') {
      obj = Object.assign(obj, patient)
      obj[name] = option.value
      setPatient(obj)
    } else if (state === 'session') {
      obj = Object.assign(obj, session)
      obj[name] = option.value
      setSession(obj)
    } else if (state === 'organigram') {
      obj = Object.assign(obj, patient)
      obj[name] = option
      setPatient(obj)
    } else if (state === 'session_summary') {
      obj = Object.assign(obj, sessionSummary)
      obj[name] = option
      setSessionSummary(obj)
    }
  }

  // modal de la caracterización de la llamada
  let modal = null
  if (openModal) {
    modal = (
      <CharacterCall
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        callCharacterizations={callCharacterizations}
        callCharacterizationID={callCharacterizationID}
        setCallCharacterizationID={setCallCharacterizationID}
      />
    )
  }

  // retorna el nombre de la caracterización de la llamada
  function getCallCharacterizationName () {
    if (!callCharacterizationID) return null

    const cc = callCharacterizations.find(i => i.id === callCharacterizationID)
    if (cc) {
      return cc.name
    }

    return null
  }

  // envía la nueva remisión
  function createRemissionRequest (consent) {
    setLocalLoginTime(0)
    postRemissionRequest({
      session_id: session.id,
      consent
    })
  }

  return (
    <Layout className='page-session'>
      <div className='call-header'>
        <div
          style={{
            fontSize: '24px',
            flexFlow: '1'
          }}
        >
          DURACIÓN DE LA LLAMADA:{' '}
          {moment
            .utc(moment.duration(callDuration, 'seconds').asMilliseconds())
            .format('HH:mm:ss')}
        </div>

        <div style={{ display: 'flex' }}>
          <div>SESSION Nº {session.id}</div>

          {callCharacterizationID ? (
            <div style={{ marginLeft: '1em' }}>
              {getCallCharacterizationName()}
            </div>
          ) : (
            <div
              style={{
                cursor: 'pointer',
                backgroundColor: 'white',
                color: 'var(--color2)',
                padding: '0 0.5rem',
                fontWeight: 'bold',
                marginLeft: '1rem'
              }}
              onClick={() => {
                setOpenModal(true)
              }}
            >
              FINALIZAR LLAMADA
            </div>
          )}
          {session.id && remitting === true ? (
            <div
              style={{
                padding: '0 0.5rem',
                fontWeight: 'bold',
                marginLeft: '1rem'
              }}
            >
              EN REMISIÓN
            </div>
          ) : null}
          {session.id && remitted === true ? (
            <div
              style={{
                padding: '0 0.5rem',
                fontWeight: 'bold',
                marginLeft: '1rem'
              }}
            >
              {/* read_remit_session (id=109) */}
              {hasPermission(109) ? (
                <Link
                  style={{
                    color: 'white',
                    display: 'inline-flex',
                    alignItems: 'center',
                    border: '1px solid white',
                    padding: '0 4px',
                    lineHeight: 1.5
                  }}
                  to={`/remit-session/?session_id=${session.id}`}
                >
                  REMITIDO
                </Link>
              ) : (
                'REMITIDO'
              )}
            </div>
          ) : null}
        </div>
      </div>

      <div style={{ display: 'flex', flexGrow: 1 }}>
        <div className='content-group'>
          {/* Paciente */}
          <details
            open
            style={{
              zIndex: 10,
              position: 'relative'
            }}
          >
            <summary>
              <div
                style={{
                  fontSize: '16px',
                  display: 'flex',
                  textTransform: 'uppercase',
                  fontWeight: 'bold'
                }}
              >
                <div style={{ marginRight: '1rem' }}>{callPhone}</div>

                <div style={{ marginRight: '1rem' }}>{patient.name}</div>

                <div style={{ marginRight: '1rem' }}>
                  {Math.trunc(
                    moment
                      .duration(moment().diff(moment(patient.birthday)))
                      .as('years')
                  )}{' '}
                  años
                </div>

                <div style={{ marginRight: '1rem' }}>
                  {(() => {
                    const population = populations.find(
                      item => item.value === patient.population_id
                    )

                    if (population) {
                      return population.label
                    }
                  })()}
                </div>

                <div style={{ marginRight: '1rem' }}>
                  {patient.civil_status}
                </div>

                <div style={{ marginRight: '1rem' }}>
                  Educación:{' '}
                  {(() => {
                    const education = educationLevels.find(
                      item => item.value === patient.education_level_id
                    )

                    if (education) {
                      return education.label
                    }
                  })()}
                </div>
              </div>

              <img src={arrowIcon} alt='flecha' className='icon-arrow' />

              {!session.is_draft ? null : (
                <button className='btn-save' onClick={pushPatient}>
                  <img
                    src={uploadIcon}
                    alt='uploadIcon'
                    className='icon-upload'
                  />
                  Guardar paciente
                </button>
              )}
            </summary>
            <Animated
              isVisible
              animationIn='zoomIn'
              animationOut='zoomOut'
              animationInDuration={50}
            >
              <div className='accordion accordion-patient'>
                <div className='accordion__group'>
                  <label className='accordion__group__item'>
                    Nombre
                    <input
                      name='name'
                      type='text'
                      value={patient.name}
                      placeholder='Nombre'
                      className='textfield'
                      onChange={handleFieldChange.bind(null, 'patient')}
                    />
                  </label>

                  <label className='accordion__group__item'>
                    Apellido
                    <input
                      name='lastname'
                      type='text'
                      value={patient.lastname}
                      placeholder='Apellido'
                      className='textfield'
                      onChange={handleFieldChange.bind(null, 'patient')}
                    />
                  </label>

                  <label className='accordion__group__item'>
                    Población
                    <div style={{ display: 'flex' }}>
                      <Select
                        isDisabled={session.id && !session.is_draft}
                        options={populations}
                        onChange={handleSelectChange.bind(
                          null,
                          'patient',
                          'population_id'
                        )}
                        value={populations.find(item => {
                          return item.value === patient.population_id
                        })}
                        placeholder='Población'
                        className='select'
                        classNamePrefix='select'
                      />
                    </div>
                  </label>

                  <div className='accordion__group__item gender-group'>
                    GÉNERO:{' '}
                    <label className='container-radio'>
                      <Radio
                        name='gender'
                        value='0'
                        checked={patient.gender === 0}
                        onChange={handleFieldChange.bind(null, 'patient')}
                      />
                      <span>Masculino</span>
                    </label>
                    <label className='container-radio'>
                      <Radio
                        name='gender'
                        value='1'
                        checked={patient.gender === 1}
                        onChange={handleFieldChange.bind(null, 'patient')}
                      />
                      <span>Femenino</span>
                    </label>
                  </div>
                </div>

                <div className='accordion__group'>
                  <label className='accordion__group__item'>
                    Cédula
                    <input
                      name='document_number'
                      type='text'
                      value={patient.document_number}
                      placeholder='Cédula'
                      className='textfield'
                      onChange={handleFieldChange.bind(null, 'patient')}
                    />
                  </label>

                  <label className='accordion__group__item'>
                    EPS
                    <div style={{ display: 'flex' }}>
                      <Select
                        isDisabled={session.id && !session.is_draft}
                        options={eps}
                        onChange={handleSelectChange.bind(
                          null,
                          'patient',
                          'eps_id'
                        )}
                        value={eps.find(item => {
                          return item.value === patient.eps_id
                        })}
                        placeholder='EPS'
                        className='select'
                        classNamePrefix='select'
                      />
                    </div>
                  </label>

                  <label className='accordion__group__item'>
                    Fecha de nacimiento
                    <input
                      type='date'
                      name='birthday'
                      value={patient.birthday}
                      placeholder='YYYY-MM-DD'
                      className='textfield'
                      onChange={handleFieldChange.bind(null, 'patient')}
                    />
                  </label>

                  <label className='accordion__group__item'>
                    Nivel de educación
                    <div style={{ display: 'flex' }}>
                      <Select
                        isDisabled={session.id && !session.is_draft}
                        options={educationLevels}
                        onChange={handleSelectChange.bind(
                          null,
                          'patient',
                          'education_level_id'
                        )}
                        value={educationLevels.find(item => {
                          return item.value === patient.education_level_id
                        })}
                        placeholder='Nivel de educación'
                        className='select'
                        classNamePrefix='select'
                      />
                    </div>
                  </label>
                </div>

                <div className='accordion__group'>
                  <label className='accordion__group__item'>
                    Estado civil
                    <Select
                      isDisabled={session.id && !session.is_draft}
                      options={CivilStatus}
                      onChange={handleSelectChange.bind(
                        null,
                        'patient',
                        'civil_status'
                      )}
                      value={CivilStatus.find(item => {
                        return item.value === patient.civil_status
                      })}
                      placeholder='Estado civil'
                      className='select'
                      classNamePrefix='select'
                    />
                  </label>

                  <label className='accordion__group__item'>
                    Desde cuando tiene ese estado civil
                    <input
                      type='date'
                      name='civil_status_date'
                      placeholder='YYYY-MM-DD'
                      value={patient.civil_status_date}
                      className='textfield'
                      onChange={handleFieldChange.bind(null, 'patient')}
                    />
                  </label>

                  <label className='accordion__group__item'>
                    Contactos de emergencia
                    <textarea
                      name='emergency_contacts'
                      value={patient.emergency_contacts}
                      onChange={handleFieldChange.bind(null, 'patient')}
                      className='textfield'
                    />
                  </label>
                </div>

                <div className='accordion__group'>
                  <div className='accordion__group__item '>
                    <div
                      style={{
                        fontSize: '1rem',
                        marginBottom: '0.5rem'
                      }}
                    >
                      TIEMPO DE SERVICIO MILITAR
                    </div>
                    <div className='group-time'>
                      <label
                        style={{
                          minWidth: '4em',
                          maxWidth: '5em'
                        }}
                      >
                        <input
                          name='service_time'
                          type='number'
                          numbertype='decimal'
                          step='0.01'
                          value={patient.service_time}
                          className='textfield'
                          onChange={handleFieldChange.bind(null, 'patient')}
                          style={{
                            textAlign: 'right'
                          }}
                        />
                      </label>

                      <div
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          flexWrap: 'wrap'
                        }}
                      >
                        {timeUnits.map((timeUnit, index) => {
                          return (
                            <label key={index} className='container-radio'>
                              <Radio
                                value={timeUnit.value}
                                checked={serviceTimeUnit === timeUnit.value}
                                onChange={() =>
                                  setServiceTimeUnit(timeUnit.value)}
                              />
                              <span>{timeUnit.label}</span>
                            </label>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <label className='accordion__group__item'>
                    Rango
                    <div style={{ display: 'flex' }}>
                      <RankSelect
                        ranks={ranks}
                        value={patient.rank_id}
                        placeholder='Rango'
                        onChange={val =>
                          setPatient({
                            ...patient,
                            rank_id: val
                          })}
                      />
                    </div>
                  </label>

                  <label className='accordion__group__item'>
                    Ubicación
                    <Select
                      isDisabled={session.id && !session.is_draft}
                      options={LocationStatuses}
                      onChange={handleSelectChange.bind(
                        null,
                        'patient',
                        'location_status'
                      )}
                      value={LocationStatuses.find(item => {
                        return item.value === patient.location_status
                      })}
                      placeholder='Ubicación'
                      className='select'
                      classNamePrefix='select'
                    />
                  </label>

                  <label className='accordion__group__item'>
                    Detalles de ubicación
                    <textarea
                      name='location_details'
                      value={patient.location_details}
                      onChange={handleFieldChange.bind(null, 'patient')}
                      className='textfield'
                    />
                  </label>
                </div>

                <div className='accordion__group'>
                  <label className='accordion__group__item'>
                    Comandante
                    <input
                      name='commander'
                      type='text'
                      value={patient.commander}
                      placeholder='Comandante'
                      className='textfield'
                      onChange={handleFieldChange.bind(null, 'patient')}
                    />
                  </label>

                  <label className='accordion__group__item'>
                    Contingente
                    <input
                      name='contingent'
                      type='text'
                      value={patient.contingent}
                      placeholder='Contingente'
                      className='textfield'
                      onChange={handleFieldChange.bind(null, 'patient')}
                    />
                  </label>

                  <label className='accordion__group__item'>
                    Organigrama
                    <div style={{ display: 'flex' }}>
                      <OrganigramSelect
                        organigram={organigrams}
                        onChange={val =>
                          setPatient({
                            ...patient,
                            organigram_id: val
                          })}
                        value={patient.organigram_id}
                        placeholder='Organigrama'
                      />
                      <button
                        className='btn-plus'
                        onClick={() => {
                          confirmAlert({
                            customUI: ({ onClose }) => {
                              return (
                                <OrganigramForm
                                  title='Nuevo organigrama'
                                  uri='organigrams'
                                  fn={setOrganigrams}
                                  organigrams={organigrams}
                                  onClose={onClose}
                                />
                              )
                            },
                            closeOnEscape: false,
                            closeOnClickOutside: false
                          })
                        }}
                      >
                        +
                      </button>
                    </div>
                  </label>

                  <label className='accordion__group__item'>
                    Detalles de organigrama
                    <textarea
                      name='organigram_details'
                      value={patient.organigram_details}
                      onChange={handleFieldChange.bind(null, 'patient')}
                      className='textfield'
                    />
                  </label>
                </div>
              </div>
            </Animated>
          </details>

          <div className='channel-row'>
            <div
              style={{
                width: '280px',
                fontSize: '16px',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              CÓMO SE ENTERÓ DE LA LÍNEA
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                justifyContent: 'space-between'
              }}
            >
              {sourceChannels.map(channel => {
                return (
                  <label
                    key={channel.value}
                    style={{
                      display: 'inline-flex',
                      margin: '0 0.5rem',
                      alignItems: 'center'
                    }}
                  >
                    <Radio
                      type='radio'
                      name='source_channel_id'
                      value={channel.value}
                      checked={channel.value === patient.source_channel_id}
                      onChange={handleFieldChange.bind(null, 'patient')}
                      containerclassname='size-18 flex-end'
                    />
                    <div
                      style={{
                        marginLeft: '0.25rem'
                      }}
                    >
                      {channel.label}
                    </div>
                  </label>
                )
              })}
            </div>
          </div>

          {!patientID ? null : (
            <>
              <details className='detail-group init-call'>
                <summary>
                  <span style={{ fontSize: '18px' }}>INICIO DE LA LLAMADA</span>
                  <img src={arrowIcon} alt='flecha' className='icon-arrow' />
                </summary>
                <Animated
                  isVisible
                  animationIn='zoomIn'
                  animationOut='zoomOut'
                  animationInDuration={50}
                  animationOutDuration={400}
                >
                  <div className='accordion'>

                    <div className='accordion__group'>
                      <h2>CONDICIÓN INICIO</h2>

                      <details className='accordion--mini'>
                        <summary>
                          <span>
                            1. Actitud del consultante: comunicación verbal.
                          </span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          {(() => {
                            return (
                              <div className='patient-state'>
                                <div className='radio-name'> </div>
                                <div className='radios'>
                                  <div>1</div>
                                  <div>2</div>
                                  <div>3</div>
                                  <div>4</div>
                                  <div>5</div>
                                </div>
                                <div className='radio-name'> </div>
                              </div>
                            )
                          })()}

                          {patientStates
                            .filter(ps => ps.category === 'actitud')
                            .map(ps => {
                              const radios = []

                              for (let i = ps.minimum; i <= ps.maximum; i++) {
                                radios.push(
                                  <Radio
                                    key={`ps1_${ps.id}_${i}`}
                                    name={`ps1_${ps.id}_${i}`}
                                    value={i}
                                    checked={
                                      patientState.findIndex(
                                        item =>
                                          item.patient_state_id === ps.id &&
                                          item.during_call === 1 &&
                                          item.value === i
                                      ) !== -1
                                    }
                                    onChange={e =>
                                      handlePatientStateChange(
                                        {
                                          during_call: 1,
                                          patient_state_id: ps.id,
                                          session_id: session.id,
                                          value: i
                                        },
                                        e)}
                                  />
                                )
                              }

                              return (
                                <div
                                  key={`c_${ps.id}`}
                                  className='patient-state'
                                >
                                  <div className='radio-name'>{ps.name1}</div>
                                  <div className='radios'>{radios}</div>
                                  <div className='radio-name'>{ps.name2}</div>
                                </div>
                              )
                            })}
                        </Animated>
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>2. Disposición del consultante</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          {(() => {
                            return (
                              <div className='patient-state'>
                                <div className='radio-name'> </div>
                                <div className='radios'>
                                  <div>1</div>
                                  <div>2</div>
                                  <div>3</div>
                                  <div>4</div>
                                  <div>5</div>
                                </div>
                                <div className='radio-name'> </div>
                              </div>
                            )
                          })()}

                          {patientStates
                            .filter(ps => ps.category === 'disposición')
                            .map(ps => {
                              const radios = []

                              for (let i = ps.minimum; i <= ps.maximum; i++) {
                                radios.push(
                                  <Radio
                                    key={`ps1_${ps.id}_${i}`}
                                    name={`ps1_${ps.id}_${i}`}
                                    checked={(() => {
                                      const pIndex = patientState.findIndex(
                                        item =>
                                          item.patient_state_id === ps.id &&
                                          item.during_call === 1 &&
                                          item.value === i
                                      )
                                      return pIndex !== -1
                                    })()}
                                    onChange={handlePatientStateChange.bind(
                                      null,
                                      {
                                        during_call: 1,
                                        patient_state_id: ps.id,
                                        session_id: session.id,
                                        value: i
                                      }
                                    )}
                                  />
                                )
                              }

                              return (
                                <div
                                  key={`c_${ps.id}`}
                                  className='patient-state'
                                >
                                  <div className='radio-name'>{ps.name1}</div>
                                  <div className='radios'>{radios}</div>
                                  <div className='radio-name'>{ps.name2}</div>
                                </div>
                              )
                            })}
                        </Animated>
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>3. Estado emocional del consultante</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          {(() => {
                            return (
                              <div className='patient-state'>
                                <div className='radio-name'> </div>
                                <div className='radios'>
                                  <div>1</div>
                                  <div>2</div>
                                  <div>3</div>
                                  <div>4</div>
                                  <div>5</div>
                                </div>
                                <div className='radio-name'> </div>
                              </div>
                            )
                          })()}

                          {patientStates
                            .filter(ps => ps.category === 'emocional')
                            .map(ps => {
                              const radios = []

                              for (let i = ps.minimum; i <= ps.maximum; i++) {
                                radios.push(
                                  <Radio
                                    key={`ps1_${ps.id}_${i}`}
                                    name={`ps1_${ps.id}_${i}`}
                                    value={i}
                                    checked={(() => {
                                      const pIndex = patientState.findIndex(
                                        item =>
                                          item.patient_state_id === ps.id &&
                                          item.during_call === 1 &&
                                          item.value === i
                                      )
                                      return pIndex !== -1
                                    })()}
                                    onChange={handlePatientStateChange.bind(
                                      null,
                                      {
                                        during_call: 1,
                                        patient_state_id: ps.id,
                                        session_id: session.id,
                                        value: i
                                      }
                                    )}
                                  />
                                )
                              }

                              return (
                                <div
                                  key={`c_${ps.id}`}
                                  className='patient-state'
                                >
                                  <div className='radio-name'>{ps.name1}</div>
                                  <div className='radios'>{radios}</div>
                                  <div className='radio-name'>{ps.name2}</div>
                                </div>
                              )
                            })}
                        </Animated>
                      </details>
                    </div>

                    <div className='accordion__group'>
                      <h2>MOTIVO DE CONSULTA</h2>

                      <div style={{ padding: '1rem' }}>
                        {reasons.map(reason => (
                          <label
                            key={reason.value}
                            style={{
                              display: 'flex',
                              color: 'white',
                              margin: '0.5rem 0',
                              alignItems: 'center'
                            }}
                          >
                            <div>
                              <Checkbox
                                disabled={!session.is_draft}
                                checked={selectedReasons.includes(reason.value)}
                                onChange={e => {
                                  if (e.target.checked) {
                                    setSelectedReasons([
                                      ...selectedReasons,
                                      reason.value
                                    ])
                                  } else {
                                    setSelectedReasons(
                                      selectedReasons.filter(
                                        rid => rid !== reason.value
                                      )
                                    )
                                  }
                                }}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: '0.5rem',
                                display: 'inline-flex'
                              }}
                            >
                              {reason.label}
                            </div>
                          </label>
                        ))}
                      </div>
                    </div>

                    <div className='accordion__group'>
                      <h2>ÁREA DE AJUSTE</h2>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Precipitante</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          <div style={{ padding: '1rem' }}>
                            {precipitants.map(precipitant => (
                              <label
                                key={precipitant.value}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'white',
                                  margin: '0.5rem 0'
                                }}
                              >
                                <div>
                                  <Checkbox
                                    disabled={!session.is_draft}
                                    checked={selectedPrecipitants.includes(
                                      precipitant.value
                                    )}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setSelectedPrecipitants([
                                          ...selectedPrecipitants,
                                          precipitant.value
                                        ])
                                      } else {
                                        setSelectedPrecipitants(
                                          selectedPrecipitants.filter(
                                            rid => rid !== precipitant.value
                                          )
                                        )
                                      }
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    marginLeft: '0.5rem',
                                    display: 'inline-flex'
                                  }}
                                >
                                  {precipitant.label}
                                </div>
                              </label>
                            ))}

                            <button
                              className='btn-plus'
                              onClick={() => {
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <ParameterForm
                                        title='Nuevo precipitante'
                                        uri='precipitants'
                                        fn={setPrecipitants}
                                        onClose={onClose}
                                      />
                                    )
                                  },
                                  closeOnEscape: false,
                                  closeOnClickOutside: false
                                })
                              }}
                              style={{
                                marginTop: '1rem'
                              }}
                            >
                              Otro
                            </button>
                          </div>
                        </Animated>
                      </details>

                      <div
                        className='accordion__group__item'
                        style={{
                          padding: '0.5rem 1rem'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '1rem'
                          }}
                        >
                          TIEMPO CON LA DIFICULTAD
                        </div>
                        <div className='group-time'>
                          <label style={{ maxWidth: '6em' }}>
                            <input
                              name='difficulty_time'
                              type='number'
                              value={session.difficulty_time}
                              className='textfield'
                              onChange={handleFieldChange.bind(null, 'session')}
                              style={{
                                textAlign: 'right'
                              }}
                            />
                          </label>

                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap'
                            }}
                          >
                            {timeUnits.map((timeUnit, index) => {
                              return (
                                <label key={index} className='container-radio'>
                                  <Radio
                                    value={timeUnit.value}
                                    checked={
                                      difficultyTimeUnit === timeUnit.value
                                    }
                                    onChange={() =>
                                      setDifficultyTimeUnit(timeUnit.value)}
                                  />
                                  <span>{timeUnit.label}</span>
                                </label>
                              )
                            })}
                          </div>
                        </div>
                      </div>

                      <details className='accordion--mini'>
                        <summary>
                          <span>ESCALA DE EXPOSICIÓN AL COMBATE</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <div style={{ padding: '1rem' }}>
                          {combatExposure.map(ce => (
                            <div
                              key={ce.value}
                              style={{
                                display: 'block',
                                color: 'white',
                                margin: '0 0 2rem 0'
                              }}
                            >
                              <div
                                style={{
                                  margin: '0'
                                }}
                              >
                                {ce.label}
                              </div>

                              <div
                                style={{
                                  display: 'flex'
                                }}
                              >
                                {[0, 1, 2, 3, 4, 5].map(val => {
                                  const isChecked =
                                    selectedCombatExposure.find(
                                      item =>
                                        item.id === ce.value &&
                                        item.scale === val
                                    ) !== undefined

                                  return (
                                    <label
                                      key={val}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '0',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <div>{val === 0 ? 'NA' : val}</div>
                                      <Radio
                                        name={`combat_exposure_${ce.value}`}
                                        value={val}
                                        style={{
                                          marginRight: '0.5rem'
                                        }}
                                        checked={isChecked}
                                        onChange={e => {
                                          // quita el item de los seleccionados en caso de que esté ahí
                                          const sce = selectedCombatExposure.filter(
                                            item => item.id !== ce.value
                                          )

                                          if (e.target.checked) {
                                            // lo agrega a los seleccionados
                                            setSelectedCombatExposure([
                                              ...sce,
                                              {
                                                id: ce.value,
                                                scale: parseInt(
                                                  e.target.value,
                                                  10
                                                )
                                              }
                                            ])
                                          } else {
                                            // confirma que se quite de los seleccionados
                                            setSelectedCombatExposure(sce)
                                          }
                                        }}
                                      />
                                    </label>
                                  )
                                })}
                              </div>
                            </div>
                          ))}

                          <div
                            style={{
                              border: '1px solid white',
                              padding: '0.5rem',
                              color: 'white'
                            }}
                          >
                            <strong>Fuente:</strong>{' '}
                            <a
                              style={{
                                color: 'white'
                              }}
                              href='https://s3.amazonaws.com/lineaendefensadelavida.com/id01555.pdf'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              Keane, T.M., Fairbank, J.A., Caddell, J.M.,
                              Zimering, R.T., Taylor, K.L., & Mora, C. (1989).
                              Clinical evaluation of a measure to assess
                              combat exposure (PDF). Psychological Assessment,
                              1, 53-55. doi: 10.1037/1040-3590.1.1.53 PTSDpubs
                              ID: 01555
                            </a>
                          </div>
                        </div>

                      </details>

                      <div className='checks-group'>
                        ¿ÁREA FAMILIAR AFECTADA?:{' '}
                        <label className='container-radio'>
                          <Radio
                            name='familiar_area_check'
                            value='0'
                            checked={session.familiar_area_check === 0}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>No</span>
                        </label>
                        <label className='container-radio'>
                          <Radio
                            name='familiar_area_check'
                            value='1'
                            checked={session.familiar_area_check === 1}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>Sí</span>
                        </label>
                      </div>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Descripción Área Familiar</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <textarea
                          style={{
                            padding: '0.5rem',
                            minHeight: '12em'
                          }}
                          name='familiar_area'
                          value={session.familiar_area}
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                          placeholder='Área familiar'
                        />
                      </details>

                      <div className='checks-group'>
                        ¿ÁREA LABORAL AFECTADA?:{' '}
                        <label className='container-radio'>
                          <Radio
                            name='work_area_check'
                            value='0'
                            checked={session.work_area_check === 0}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>No</span>
                        </label>
                        <label className='container-radio'>
                          <Radio
                            name='work_area_check'
                            value='1'
                            checked={session.work_area_check === 1}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>Sí</span>
                        </label>
                      </div>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Descripción Área Laboral</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <textarea
                          style={{
                            padding: '0.5rem',
                            minHeight: '12em'
                          }}
                          name='work_area'
                          value={session.work_area}
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                          placeholder='Área laboral'
                        />
                      </details>

                      <div className='checks-group'>
                        ¿ÁREA ACADÉMICA AFECTADA?:{' '}
                        <label className='container-radio'>
                          <Radio
                            name='academic_area_check'
                            value='0'
                            checked={session.academic_area_check === 0}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>No</span>
                        </label>
                        <label className='container-radio'>
                          <Radio
                            name='academic_area_check'
                            value='1'
                            checked={session.academic_area_check === 1}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>Sí</span>
                        </label>
                      </div>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Descripción Área Académica</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <textarea
                          name='academic_area'
                          value={session.academic_area}
                          placeholder='Área académica'
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                          style={{
                            padding: '0.5rem',
                            minHeight: '12em'
                          }}
                        />
                      </details>

                      <div className='checks-group'>
                        ¿ÁREA AFECTIVA AFECTADA?:{' '}
                        <label className='container-radio'>
                          <Radio
                            name='affective_area_check'
                            value='0'
                            checked={session.affective_area_check === 0}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>No</span>
                        </label>
                        <label className='container-radio'>
                          <Radio
                            name='affective_area_check'
                            value='1'
                            checked={session.affective_area_check === 1}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>Sí</span>
                        </label>
                      </div>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Descripción Área Afectiva</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <textarea
                          name='affective_area'
                          value={session.affective_area}
                          placeholder='Área afectiva'
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                          style={{
                            padding: '0.5rem',
                            minHeight: '12em'
                          }}
                        />
                      </details>

                      <div className='checks-group'>
                        ¿ÁREA SOCIAL AFECTADA?:{' '}
                        <label className='container-radio'>
                          <Radio
                            name='social_area_check'
                            value='0'
                            checked={session.social_area_check === 0}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>No</span>
                        </label>
                        <label className='container-radio'>
                          <Radio
                            name='social_area_check'
                            value='1'
                            checked={session.social_area_check === 1}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>Sí</span>
                        </label>
                      </div>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Descripción Área Social</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <textarea
                          name='social_area'
                          value={session.social_area}
                          placeholder='Área social'
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                          style={{
                            padding: '0.5rem',
                            minHeight: '12em'
                          }}
                        />
                      </details>

                      <div className='checks-group'>
                        ¿ÁREA PERSONAL AFECTADA?:{' '}
                        <label className='container-radio'>
                          <Radio
                            name='personal_area_check'
                            value='0'
                            checked={session.personal_area_check === 0}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>No</span>
                        </label>
                        <label className='container-radio'>
                          <Radio
                            name='personal_area_check'
                            value='1'
                            checked={session.personal_area_check === 1}
                            onChange={handleFieldChange.bind(null, 'session')}
                          />
                          <span>Sí</span>
                        </label>
                      </div>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Descripción Área Personal</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <textarea
                          name='personal_area'
                          value={session.personal_area}
                          placeholder='Área personal'
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                          style={{
                            padding: '0.5rem',
                            minHeight: '12em'
                          }}
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Personas Implicadas</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          <div style={{ padding: '1rem' }}>
                            {involvedPeople.map(involvedPerson => (
                              <label
                                key={involvedPerson.value}
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  color: 'white',
                                  margin: '0.5rem 0'
                                }}
                              >
                                <div>
                                  <Checkbox
                                    disabled={!session.is_draft}
                                    checked={selectedInvolvedPeople.includes(
                                      involvedPerson.value
                                    )}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setSelectedInvolvedPeople([
                                          ...selectedInvolvedPeople,
                                          involvedPerson.value
                                        ])
                                      } else {
                                        setSelectedInvolvedPeople(
                                          selectedInvolvedPeople.filter(
                                            rid => rid !== involvedPerson.value
                                          )
                                        )
                                      }
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    marginLeft: '0.5rem',
                                    display: 'inline-flex'
                                  }}
                                >
                                  {involvedPerson.label}
                                </div>
                              </label>
                            ))}
                          </div>
                        </Animated>
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Estrategias Empleadas Anteriormente</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          <div style={{ padding: '1rem' }}>
                            {strategies.map(strategy => (
                              <label
                                key={strategy.value}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'white',
                                  margin: '0.5rem 0'
                                }}
                              >
                                <div>
                                  <Checkbox
                                    disabled={!session.is_draft}
                                    checked={selectedStrategies.includes(
                                      strategy.value
                                    )}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setSelectedStrategies([
                                          ...selectedStrategies,
                                          strategy.value
                                        ])
                                      } else {
                                        setSelectedStrategies(
                                          selectedStrategies.filter(
                                            rid => rid !== strategy.value
                                          )
                                        )
                                      }
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: 'inline-flex',
                                    marginLeft: '0.5rem'
                                  }}
                                >
                                  {strategy.label}
                                </div>
                              </label>
                            ))}
                          </div>
                        </Animated>
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Canal de Respuesta Predominante</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          <div style={{ padding: '1rem' }}>
                            {responseChannels.map(responseChannel => (
                              <label
                                key={responseChannel.value}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: 'white',
                                  margin: '0.5rem 0'
                                }}
                              >
                                <div>
                                  <Checkbox
                                    disabled={!session.is_draft}
                                    checked={selectedResponseChannels.includes(
                                      responseChannel.value
                                    )}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setSelectedResponseChannels([
                                          ...selectedResponseChannels,
                                          responseChannel.value
                                        ])
                                      } else {
                                        setSelectedResponseChannels(
                                          selectedResponseChannels.filter(
                                            rid => rid !== responseChannel.value
                                          )
                                        )
                                      }
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: 'inline-flex',
                                    marginLeft: '0.5rem'
                                  }}
                                >
                                  {responseChannel.label}
                                </div>
                              </label>
                            ))}
                          </div>
                        </Animated>
                      </details>
                    </div>

                  </div>
                </Animated>
              </details>

              <details className='detail-group during-call'>
                <summary>
                  <span style={{ fontSize: '18px' }}>DURANTE LA ATENCIÓN</span>
                  <img src={arrowIcon} alt='flecha' className='icon-arrow' />
                </summary>
                <Animated
                  isVisible
                  animationIn='zoomIn'
                  animationOut='zoomOut'
                  animationInDuration={50}
                  animationOutDuration={400}
                >
                  <div className='accordion'>

                    <div className='accordion__group'>
                      <h2>RESUMEN DEL CASO</h2>

                      <label className='accordion__group__item'>
                        Fase
                        <div style={{ display: 'flex' }}>
                          <Select
                            isDisabled={session.id && !session.is_draft}
                            options={phases}
                            onChange={handleSelectChange.bind(
                              null,
                              'session_summary',
                              'phase'
                            )}
                            value={phases.find(item => {
                              return item.value === sessionSummary.phase
                            })}
                            placeholder='Fase'
                            className='select'
                            classNamePrefix='select'
                          />
                        </div>
                      </label>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Motivo de consulta</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='reason'
                          value={sessionSummary.reason}
                          onChange={handleFieldChange.bind(
                            null,
                            'session_summary'
                          )}
                          className='textfield'
                          placeholder='Motivo de consulta'
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Objetivos</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='objectives'
                          value={sessionSummary.objectives}
                          onChange={handleFieldChange.bind(
                            null,
                            'session_summary'
                          )}
                          className='textfield'
                          placeholder='Objetivos'
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Impresión diagnóstica</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='diagnostic_impression'
                          value={sessionSummary.diagnostic_impression}
                          onChange={handleFieldChange.bind(
                            null,
                            'session_summary'
                          )}
                          className='textfield'
                          placeholder='Impresión diagnóstica'
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Historia</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='history'
                          value={sessionSummary.history}
                          onChange={handleFieldChange.bind(
                            null,
                            'session_summary'
                          )}
                          className='textfield'
                          placeholder='Historia'
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Descripción de la sesión</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='session_description'
                          value={sessionSummary.session_description}
                          onChange={handleFieldChange.bind(
                            null,
                            'session_summary'
                          )}
                          className='textfield'
                          placeholder='Descripción de la sesión'
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Acción</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='session_action'
                          value={sessionSummary.session_action}
                          onChange={handleFieldChange.bind(
                            null,
                            'session_summary'
                          )}
                          className='textfield'
                          placeholder='Acción'
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Detalles del procedimiento</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='process_details'
                          value={sessionSummary.process_details}
                          onChange={handleFieldChange.bind(
                            null,
                            'session_summary'
                          )}
                          className='textfield'
                          placeholder='Detalles del procedimiento'
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Evolución respecto al proceso de evaluación</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='evaluation_evolution'
                          value={session.evaluation_evolution}
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                          placeholder='Evolución respecto al proceso de evaluación'
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Evolución fase de intervención</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='intervention_evolution'
                          value={session.intervention_evolution}
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                          placeholder='Evolución fase de intervención'
                        />
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Desarrollo de sesión</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>

                        <textarea
                          name='session_development'
                          value={session.session_development}
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                          placeholder='Desarrollo de sesión'
                        />
                      </details>
                    </div>

                    <div className='accordion__group'>
                      <h2>CASIC</h2>

                      {casic
                        .filter(item => item.parent_id === 0)
                        .map(cas0 => {
                          return (
                            <details key={cas0.id} className='accordion--mini'>
                              <summary>
                                <span>{cas0.name}</span>
                                <img
                                  src={arrowIcon}
                                  alt='flecha'
                                  className='icon-arrow'
                                />
                              </summary>
                              <Animated
                                isVisible
                                animationIn='zoomIn'
                                animationOut='zoomOut'
                                animationInDuration={50}
                                animationOutDuration={400}
                              >
                                <div
                                  style={{
                                    padding: '1rem'
                                  }}
                                >
                                  {casic
                                    .filter(item => item.parent_id === cas0.id)
                                    .map(cas1 => (
                                      <label
                                        key={cas1.id}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          color: 'white',
                                          margin: '0.5rem 0'
                                        }}
                                      >
                                        <div>
                                          <Checkbox
                                            disabled={!session.is_draft}
                                            checked={selectedCasic.includes(
                                              cas1.id
                                            )}
                                            onChange={e => {
                                              if (e.target.checked) {
                                                setSelectedCasic([
                                                  ...selectedCasic,
                                                  cas1.id
                                                ])
                                              } else {
                                                setSelectedCasic(
                                                  selectedCasic.filter(
                                                    id => id !== cas1.id
                                                  )
                                                )
                                              }
                                            }}
                                            style={{
                                              marginRight: '0.5rem'
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-flex',
                                            marginLeft: '0.5rem'
                                          }}
                                        >
                                          {cas1.name}
                                        </div>
                                      </label>
                                    ))}
                                </div>
                              </Animated>
                            </details>
                          )
                        })}

                      <details className='accordion--mini'>
                        <summary>
                          <span>Cognoscitivo</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          <textarea
                            name='casic_cognitive'
                            value={session.casic_cognitive}
                            placeholder='Cognoscitivo'
                            onChange={handleFieldChange.bind(null, 'session')}
                            className='textfield'
                            style={{
                              padding: '0.5rem',
                              minHeight: '12em'
                            }}
                          />
                        </Animated>
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>Comentarios CASIC</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          <textarea
                            name='casic_comments'
                            value={session.casic_comments}
                            placeholder='Comentarios CASIC'
                            onChange={handleFieldChange.bind(null, 'session')}
                            className='textfield'
                            style={{
                              padding: '0.5rem',
                              minHeight: '12em'
                            }}
                          />
                        </Animated>
                      </details>
                    </div>

                  </div>
                </Animated>
              </details>

              <details className='detail-group after-call'>
                <summary>
                  <span style={{ fontSize: '18px' }}>
                    DESPUÉS DE LA ATENCIÓN
                  </span>
                  <img src={arrowIcon} alt='flecha' className='icon-arrow' />
                </summary>
                <Animated
                  isVisible
                  animationIn='zoomIn'
                  animationOut='zoomOut'
                  animationInDuration={50}
                  animationOutDuration={400}
                >
                  <div className='accordion'>
                    <div className='accordion__group'>
                      <h2>CONDICIÓN FINAL</h2>

                      <details className='accordion--mini'>
                        <summary>
                          <span>
                            1. Actitud del consultante: comunicación verbal.
                          </span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          {(() => {
                            return (
                              <div className='patient-state'>
                                <div className='radio-name'> </div>
                                <div className='radios'>
                                  <div>1</div>
                                  <div>2</div>
                                  <div>3</div>
                                  <div>4</div>
                                  <div>5</div>
                                </div>
                                <div className='radio-name'> </div>
                              </div>
                            )
                          })()}

                          {patientStates
                            .filter(ps => ps.category === 'actitud')
                            .map(ps => {
                              const radios = []

                              for (let i = ps.minimum; i <= ps.maximum; i++) {
                                radios.push(
                                  <Radio
                                    key={`ps0_${ps.id}_${i}`}
                                    name={`ps0_${ps.id}_${i}`}
                                    value={i}
                                    checked={
                                      patientState.findIndex(
                                        item =>
                                          item.patient_state_id === ps.id &&
                                          item.during_call === 0 &&
                                          item.value === i
                                      ) !== -1
                                    }
                                    onChange={e =>
                                      handlePatientStateChange(
                                        {
                                          during_call: 0,
                                          patient_state_id: ps.id,
                                          session_id: session.id,
                                          value: i
                                        },
                                        e)}
                                  />
                                )
                              }

                              return (
                                <div
                                  key={`c_${ps.id}`}
                                  className='patient-state'
                                >
                                  <div className='radio-name'>{ps.name1}</div>
                                  <div className='radios'>{radios}</div>
                                  <div className='radio-name'>{ps.name2}</div>
                                </div>
                              )
                            })}
                        </Animated>
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>2. Disposición del consultante</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          {(() => {
                            return (
                              <div className='patient-state'>
                                <div className='radio-name'> </div>
                                <div className='radios'>
                                  <div>1</div>
                                  <div>2</div>
                                  <div>3</div>
                                  <div>4</div>
                                  <div>5</div>
                                </div>
                                <div className='radio-name'> </div>
                              </div>
                            )
                          })()}

                          {patientStates
                            .filter(ps => ps.category === 'disposición')
                            .map(ps => {
                              const radios = []

                              for (let i = ps.minimum; i <= ps.maximum; i++) {
                                radios.push(
                                  <Radio
                                    key={`ps0_${ps.id}_${i}`}
                                    name={`ps0_${ps.id}_${i}`}
                                    checked={(() => {
                                      const pIndex = patientState.findIndex(
                                        item =>
                                          item.patient_state_id === ps.id &&
                                          item.during_call === 0 &&
                                          item.value === i
                                      )
                                      return pIndex !== -1
                                    })()}
                                    onChange={handlePatientStateChange.bind(
                                      null,
                                      {
                                        during_call: 0,
                                        patient_state_id: ps.id,
                                        session_id: session.id,
                                        value: i
                                      }
                                    )}
                                  />
                                )
                              }

                              return (
                                <div
                                  key={`c_${ps.id}`}
                                  className='patient-state'
                                >
                                  <div className='radio-name'>{ps.name1}</div>
                                  <div className='radios'>{radios}</div>
                                  <div className='radio-name'>{ps.name2}</div>
                                </div>
                              )
                            })}
                        </Animated>
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>3. Estado emocional del consultante</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          {(() => {
                            return (
                              <div className='patient-state'>
                                <div className='radio-name'> </div>
                                <div className='radios'>
                                  <div>1</div>
                                  <div>2</div>
                                  <div>3</div>
                                  <div>4</div>
                                  <div>5</div>
                                </div>
                                <div className='radio-name'> </div>
                              </div>
                            )
                          })()}

                          {patientStates
                            .filter(ps => ps.category === 'emocional')
                            .map(ps => {
                              const radios = []

                              for (let i = ps.minimum; i <= ps.maximum; i++) {
                                radios.push(
                                  <Radio
                                    key={`ps0_${ps.id}_${i}`}
                                    name={`ps0_${ps.id}_${i}`}
                                    value={i}
                                    checked={(() => {
                                      const pIndex = patientState.findIndex(
                                        item =>
                                          item.patient_state_id === ps.id &&
                                          item.during_call === 0 &&
                                          item.value === i
                                      )
                                      return pIndex !== -1
                                    })()}
                                    onChange={handlePatientStateChange.bind(
                                      null,
                                      {
                                        during_call: 0,
                                        patient_state_id: ps.id,
                                        session_id: session.id,
                                        value: i
                                      }
                                    )}
                                  />
                                )
                              }

                              return (
                                <div
                                  key={`c_${ps.id}`}
                                  className='patient-state'
                                >
                                  <div className='radio-name'>{ps.name1}</div>
                                  <div className='radios'>{radios}</div>
                                  <div className='radio-name'>{ps.name2}</div>
                                </div>
                              )
                            })}
                        </Animated>
                      </details>

                      <details className='accordion--mini'>
                        <summary>
                          <span>4. Estado general al finalizar la llamada</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                        >
                          {(() => {
                            return (
                              <div className='patient-state'>
                                <div className='radio-name'> </div>
                                <div className='radios'>
                                  <div>1</div>
                                  <div>2</div>
                                  <div>3</div>
                                  <div>4</div>
                                  <div>5</div>
                                </div>
                              </div>
                            )
                          })()}

                          {patientStates
                            .filter(ps => ps.category === 'general_final')
                            .map(ps => {
                              const radios = []

                              for (let i = ps.minimum; i <= ps.maximum; i++) {
                                radios.push(
                                  <Radio
                                    key={`ps0_${ps.id}_${i}`}
                                    name={`ps0_${ps.id}`}
                                    value={i}
                                    checked={(() => {
                                      const pIndex = patientState.findIndex(
                                        item =>
                                          item.patient_state_id === ps.id &&
                                          item.during_call === 0 &&
                                          item.value === i
                                      )
                                      return pIndex !== -1
                                    })()}
                                    onChange={handlePatientStateChange.bind(
                                      null,
                                      {
                                        during_call: 0,
                                        patient_state_id: ps.id,
                                        session_id: session.id,
                                        value: i
                                      }
                                    )}
                                  />
                                )
                              }

                              return (
                                <div
                                  key={`c_${ps.id}`}
                                  className='patient-state'
                                >
                                  <div className='radio-name'>{ps.name1}</div>
                                  <div className='radios'>{radios}</div>
                                </div>
                              )
                            })}
                        </Animated>
                      </details>
                    </div>

                    <div className='accordion__group'>
                      <h2>IMPRESIÓN DIAGNÓSTICA</h2>

                      <label className='accordion__group__item'>
                        Impresión diagnóstica DSM V
                        <div style={{ display: 'flex' }}>
                          <DiagnosticSelect
                            diagnostics={diagnostics}
                            value={selectedDSM || null}
                            placeholder='DSM V'
                            onChange={val => setSelectedDSM(val)}
                            catalogId={1}
                          />
                        </div>
                      </label>

                      <label className='accordion__group__item'>
                        Impresión diagnóstica CIE 10
                        <div style={{ display: 'flex' }}>
                          <DiagnosticSelect
                            diagnostics={diagnostics}
                            value={selectedCIE || null}
                            placeholder='CIE 10'
                            onChange={val => setSelectedCIE(val)}
                            catalogId={2}
                            canSelectAny
                          />
                        </div>
                      </label>

                      <label className='accordion__group__item'>
                        Descripción de la impresión diagnóstica
                        <textarea
                          name='diagnostic_description'
                          value={session.diagnostic_description}
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                        />
                      </label>

                      <label className='accordion__group__item'>
                        Problemática
                        <div style={{ display: 'flex' }}>
                          <ProblematicSelect
                            problematic={problematics}
                            value={session.problematic_id || null}
                            placeholder='Problemática'
                            onChange={val =>
                              setSession({
                                ...session,
                                problematic_id: val
                              })}
                            canSelectAny
                          />
                        </div>
                      </label>

                      <label className='accordion__group__item'>
                        Descripción de la problemática
                        <textarea
                          name='problematic_description'
                          value={session.problematic_description}
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                        />
                      </label>

                      <details className='accordion--mini' open>
                        <summary>
                          <span>Prioridad</span>
                          <img
                            src={arrowIcon}
                            alt='flecha'
                            className='icon-arrow'
                          />
                        </summary>
                        <Animated
                          isVisible
                          animationIn='zoomIn'
                          animationOut='zoomOut'
                          animationInDuration={50}
                          animationOutDuration={400}
                          style={{
                            display: 'flex',
                            color: 'white',
                            alignItems: 'center'
                          }}
                        >
                          <label
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center'
                            }}
                          >
                            <Radio
                              name='casic_priority'
                              checked={session.casic_priority === 1}
                              value={1}
                              onChange={handleFieldChange.bind(null, 'session')}
                              containerclassname='inline-radio'
                            />
                            <div
                              style={{
                                marginLeft: '0.5rem'
                              }}
                            >
                              Alta
                            </div>
                          </label>
                          <label
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center'
                            }}
                          >
                            <Radio
                              name='casic_priority'
                              checked={session.casic_priority === 2}
                              value={2}
                              onChange={handleFieldChange.bind(null, 'session')}
                              containerclassname='inline-radio'
                            />
                            <div
                              style={{
                                marginLeft: '0.5rem'
                              }}
                            >
                              Media
                            </div>
                          </label>
                          <label
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center'
                            }}
                          >
                            <Radio
                              name='casic_priority'
                              checked={session.casic_priority === 3}
                              value={3}
                              onChange={handleFieldChange.bind(null, 'session')}
                              containerclassname='inline-radio'
                            />
                            <div
                              style={{
                                marginLeft: '0.5rem'
                              }}
                            >
                              Baja
                            </div>
                          </label>
                        </Animated>
                      </details>
                    </div>

                    <div className='accordion__group'>
                      <h2>PROCEDIMIENTOS</h2>
                      <label className='accordion__group__item'>
                        Procedimiento
                        <div style={{ display: 'flex' }}>
                          <Select
                            isDisabled={session.id && !session.is_draft}
                            options={processes}
                            onChange={handleSelectChange.bind(
                              null,
                              'session',
                              'process_id'
                            )}
                            value={processes.find(item => {
                              return item.value === session.process_id
                            })}
                            placeholder='Procedimiento'
                            className='select'
                            classNamePrefix='select'
                          />
                        </div>
                      </label>

                      <label className='accordion__group__item'>
                        Resultados del procedimiento
                        <div style={{ display: 'flex' }}>
                          <Select
                            isDisabled={session.id && !session.is_draft}
                            options={processResults}
                            onChange={handleSelectChange.bind(
                              null,
                              'session',
                              'process_result_id'
                            )}
                            value={processResults.find(item => {
                              return item.value === session.process_result_id
                            })}
                            placeholder='Resultados del procedimiento'
                            className='select'
                            classNamePrefix='select'
                          />
                        </div>
                      </label>
                    </div>

                    <div className='accordion__group'>
                      <h2>SEGUIMIENTO</h2>

                      <label className='accordion__group__item'>
                        Fecha de la próxima llamada
                        <input
                          type='date'
                          name='next_call_date'
                          placeholder='YYYY-MM-DD'
                          value={session.next_call_date}
                          className='textfield'
                          onChange={handleFieldChange.bind(null, 'session')}
                        />
                      </label>

                      <div style={{ padding: '1rem' }}>
                        <div
                          style={{
                            color: 'white',
                            borderBottom: '1px solid white'
                          }}
                        >
                          Aspectos a evaluar en la próxima llamada
                        </div>

                        {nextCallAspects.map(aspect => (
                          <label
                            key={aspect.value}
                            style={{
                              display: 'flex',
                              color: 'white',
                              margin: '0.5rem 0',
                              alignItems: 'center'
                            }}
                          >
                            <div>
                              <Checkbox
                                disabled={!session.is_draft}
                                checked={selectedNextCallAspects.includes(aspect.value)}
                                onChange={e => {
                                  if (e.target.checked) {
                                    setSelectedNextCallAspects([
                                      ...selectedNextCallAspects,
                                      aspect.value
                                    ])
                                  } else {
                                    setSelectedNextCallAspects(
                                      selectedNextCallAspects.filter(
                                        id => id !== aspect.value
                                      )
                                    )
                                  }
                                }}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: '0.5rem',
                                display: 'inline-flex'
                              }}
                            >
                              {aspect.label}
                            </div>
                          </label>
                        ))}
                      </div>

                      <div style={{ padding: '1rem' }}>
                        <div
                          style={{
                            color: 'white',
                            borderBottom: '1px solid white'
                          }}
                        >
                          Tareas a realizar en la próxima llamada
                        </div>

                        {todotasks.map(todotask => (
                          <label
                            key={todotask.value}
                            style={{
                              display: 'flex',
                              color: 'white',
                              margin: '0.5rem 0',
                              alignItems: 'center'
                            }}
                          >
                            <div>
                              <Checkbox
                                disabled={!session.is_draft}
                                checked={selectedTodotasks.includes(todotask.value)}
                                onChange={e => {
                                  if (e.target.checked) {
                                    setSelectedTodotasks([
                                      ...selectedTodotasks,
                                      todotask.value
                                    ])
                                  } else {
                                    setSelectedTodotasks(
                                      selectedTodotasks.filter(
                                        id => id !== todotask.value
                                      )
                                    )
                                  }
                                }}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: '0.5rem',
                                display: 'inline-flex'
                              }}
                            >
                              {todotask.label}
                            </div>
                          </label>
                        ))}
                      </div>

                      <label className='accordion__group__item'>
                        Más detalles de la próxima llamada
                        <textarea
                          name='task_details'
                          value={session.task_details}
                          onChange={handleFieldChange.bind(null, 'session')}
                          className='textfield'
                        />
                      </label>
                    </div>
                  </div>
                </Animated>
              </details>
            </>
          )}
        </div>

        {/* Notas de la llamada */}
        <div className={`notes ${showNotes ? '' : 'notes-hidden'}`}>
          <div className='toggle' onClick={() => setShowNotes(!showNotes)}>
            <img src={arrowIcon} alt='flecha' className='icon-arrow' />
            OTROS DATOS Y NOVEDADES
          </div>

          <div className='content'>
            <h2 style={{ color: 'white' }}>OTROS DATOS Y NOVEDADES</h2>
            <textarea
              name='notes'
              value={notes}
              onChange={e => {
                setNotes(e.target.value)
                setLocalLoginTime(0)
              }}
              className='textfield'
              style={{
                background: 'white',
                color: 'var(--color1)'
              }}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          textAlign: 'center',
          padding: '2rem 0'
        }}
      >
        {patientID && session.is_draft ? (
          <>
            <button
              className='btn-save'
              onClick={() => {
                confirmPushSession(true)
              }}
              style={{
                display: 'inline-flex',
                fontSize: '1rem',
                padding: '0 1rem',
                backgroundColor: 'white',
                color: 'var(--color3)',
                fontWeight: 'bold'
              }}
            >
              GUARDAR SESIÓN
            </button>

            <button
              className='btn-save'
              onClick={() => {
                if (!callCharacterizationID) {
                  toast.warning(
                    'No se puede completar la sesión, primero debes finalizar la llamada'
                  )
                  return
                }

                confirmAlert({
                  title: '¿Desea completar la sesión?',
                  message:
                    'Si realiza esta acción, la sesión no se podrá editar.',
                  buttons: [
                    {
                      label: 'Cancelar'
                    },
                    {
                      label: 'Confirmar',
                      onClick: () => confirmPushSession(false)
                    }
                  ]
                })
              }}
              style={{
                display: 'inline-flex',
                fontSize: '1rem',
                padding: '0 1rem',
                backgroundColor: 'white',
                color: 'var(--color3)',
                fontWeight: 'bold'
              }}
            >
              COMPLETAR SESIÓN
            </button>
          </>
        ) : null}

        {!remitting && !remitted && session.id ? (
          <button
            onClick={() => setOpenRemission(true)}
            className='btn-save'
            style={{
              display: 'inline-flex',
              fontSize: '1rem',
              padding: '0 1rem',
              backgroundColor: 'white',
              color: 'var(--color3)',
              fontWeight: 'bold'
            }}
          >
            REMITIR
          </button>
        ) : null}
      </div>

      {openRemission ? (
        <RemissionPopup
          isOpen={openRemission}
          onClose={res => {
            setOpenRemission(false)
            if (res !== undefined) {
              setRemitting(true)
              createRemissionRequest(res)
            }
          }}
        />
      ) : null}

      {openCreateRemission ? (
        <CreateRemissionPopup
          isOpen={openCreateRemission}
          onClose={remitting => {
            setOpenCreateRemission(false)
            setRemitting(remitting)
          }}
          sessionId={session.id}
          callDuration={callDuration}
        />
      ) : null}

      {/* Modal para remisión */}
      {modal}

      <ConfirmModal {...confirmModal} />
    </Layout>
  )
}

export default SessionPage
