import React, { useState } from 'react'
import { toast } from 'react-toastify'

import './styles.scss'

import PUSH from '../../dao/push'
import GET from '../../dao/get'
import { OrganigramSelect } from '../OrganigramSelect'

function Form (props) {
  const [name, setName] = useState('')
  const [parentID, setParentID] = useState(1)

  function submit () {
    PUSH('PUT', props.uri, { name, parent_id: parentID }).then(res => {
      if (res.status >= 400) {
        toast.error(`Error: ${res.body.message}`)
      } else {
        toast.success('Se creó el elemento de organigrama correctamente')

        // TODO: usar el dato que devuelve el backend en lugar de hacer de nuevo este GET.
        GET(props.uri).then(res => {
          if (res.status >= 400) {
            toast.error(res.body.message)
          } else {
            props.fn(res.body)

            props.onClose()
          }
        })
      }
    })
  }

  return (
    <div className='form-container'>
      <h2>{props.title}</h2>

      <label>
        Nombre del organigrama
        <input
          type='text'
          value={name}
          onChange={e => setName(e.target.value)}
          className='form-container__textfield'
        />
      </label>

      <label>
        Es hijo de
        <OrganigramSelect
          organigram={props.organigrams}
          onChange={option => setParentID(option)}
          value={parentID}
          placeholder='Organigrama'
          canSelectAny
        />
      </label>

      <div className='buttons'>
        <button className='btn' onClick={submit}>
          Enviar
        </button>
        <button className='btn' onClick={props.onClose}>
          Cancelar
        </button>
      </div>
    </div>
  )
}

export default Form
