import axios from 'axios'
import querystring from 'querystring'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @returns {Promise}
 */
export function getDiagnostics () {
  return axios.get(API('diagnostic-catalog-items'), {
    headers: {
      Authorization: getJwtString()
    }
  })
}

export function getDiagnosticCatalogs (args) {
  let params = querystring.stringify(args)
  if (params) params = `?${params}`

  return axios.get(`${API('diagnostic-catalogs')}${params}`, {
    headers: {
      Authorization: getJwtString()
    }
  })
}
