import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Formik, Form, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import Select from 'react-select'
import moment from 'moment'
import * as Yup from 'yup'

import './styles.scss'

import closeIcon from '../../images/close.svg'
import { getUsers } from '../../services/api/users'
import { postRemission } from '../../services/api/remissions'
import { deleteRemissionRequest } from '../../services/api/remission-requests'

export function CreateRemissionPopup({
  isOpen,
  onClose,
  sessionId,
  callDuration,
}) {
  const [receptors, setReceptors] = useState([])
  Modal.setAppElement('.layout-container')

  useEffect(() => {
    // carga receptores
    getUsers({ role_id: 3 })
      .then(res => {
        setReceptors(
          res.data.map(item => {
            return {
              value: item.id,
              label: `${item.name} ${item.name2} ${item.lastname}`,
            }
          })
        )
      })
      .catch(err => {
        console.error(err.response)
        toast.error(err.response.data.message)
      })
  }, [])

  const remissionFormSchema = Yup.object().shape({
    receptor: Yup.number().min(1, '* Selecciona el receptor'),
    authorization_date: Yup.string().required(
      '* Ingresa la fecha de autorización'
    ),
    authorization_time: Yup.string().required(
      '* Ingresa la hora de autorización'
    ),
    reception_date: Yup.string().required('* Ingresa la fecha de recepción'),
    reception_time: Yup.string().required('* Ingresa la hora de recepción'),
    status: Yup.string().required('* Ingresa el estado del paciente'),
  })

  const onDeleteRemissionRequest = () => {
    deleteRemissionRequest(sessionId).catch(err => {
      console.log(err.response)
      toast.error('No se pudo eliminar la solicitud de remisión')
    })
  }

  const onSubmit = values => {
    const authorization_time = `${values.authorization_date} ${values.authorization_time}`
    const reception_time = `${values.reception_date} ${values.reception_time}`

    // se necesita el remitente, id sesion, duración llamada
    const remission = {
      session_id: sessionId,
      call_duration: callDuration,
      receiver: values.receptor,
      status: true,
      status_details: values.status,
      authorization_time,
      reception_time,
    }
    // crea remisión
    postRemission(remission)
      .then(res => {
        toast.success('Se creó la remisión correctamente')

        // eliminar solicitud de remisión
        onDeleteRemissionRequest()
        window.location.href = '/dashboard/'
      })
      .catch(err => {
        console.error(err.response)
        toast.error(err.response.data.message)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { zIndex: 999 },
        content: {
          margin: '0 auto',
          width: '600px',
          padding: '3rem 4rem 2rem 4rem',
        },
      }}
    >
      <div
        onClick={() => onClose(true)}
        style={{
          cursor: 'pointer',
          width: '24px',
          height: '24px',
          backgroundImage: `url("${closeIcon}")`,
          backgroundSize: 'cover',
          position: 'absolute',
          top: '2rem',
          right: '2rem',
        }}
      />

      <h1
        style={{
          fontSize: '20px',
          margin: '1em auto',
          maxWidth: '600px',
          textAlign: 'center',
          fontWeight: 700,
          textTransform: 'uppercase',
        }}
      >
        El caso ha sido remitido exitosamente
      </h1>

      <div
        style={{
          margin: '2rem auto',
          maxWidth: '300px',
          display: 'flex',
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        Para finalizar el proceso es necesaria la siguiente información
      </div>

      <Formik
        onSubmit={onSubmit}
        initialValues={{
          receptor: 0,
          status: '',
          authorization_date: moment().format('YYYY-MM-DD'),
          authorization_time: moment()
            .subtract(15, 'minutes')
            .format('HH:mm'),
          reception_date: moment().format('YYYY-MM-DD'),
          reception_time: moment()
            .subtract(10, 'minutes')
            .format('HH:mm'),
        }}
        validationSchema={remissionFormSchema}
        render={({ values, setFieldValue }) => (
          <Form className="create_remission">
            <div style={{ margin: '1rem 0' }}>
              <label>
                MIEMBRO DE LA DISAN QUE RECIBE LA REMISIÓN
                <Select
                  name="receptor"
                  options={receptors}
                  placeholder="¿Quién es el receptor?"
                  className="select"
                  classNamePrefix="select"
                  value={receptors.find(item => item.value === values.receptor)}
                  onChange={e => setFieldValue('receptor', e.value)}
                />
              </label>
              <ErrorMessage
                name="receptor"
                component="small"
                className="text-danger"
              />
            </div>

            <div style={{ margin: '1rem 0' }}>
              <label>
                <span style={{ display: 'block' }}>HORA DE AUTORIZACIÓN</span>
                <input
                  type="date"
                  name="authorization_date"
                  value={values.authorization_date}
                  placeholder="YYYY-MM-DD"
                  style={{
                    width: '150px',
                    display: 'inline-block',
                    padding: '0.25rem 0.5rem',
                  }}
                  onChange={e => {
                    setFieldValue('authorization_date', e.target.value)
                  }}
                />
                <input
                  type="time"
                  name="authorization_time"
                  required
                  value={values.authorization_time}
                  onChange={e => {
                    setFieldValue('authorization_time', e.target.value)
                  }}
                  style={{
                    padding: '0.25rem 0.5rem',
                  }}
                />
              </label>
              <ErrorMessage
                name="authorization_date"
                component="small"
                className="text-danger"
              />
              <ErrorMessage
                name="authorization_time"
                component="small"
                className="text-danger"
              />
            </div>

            <div style={{ margin: '1rem 0' }}>
              <label>
                <span style={{ display: 'block' }}>HORA DE RECEPCIÓN</span>
                <input
                  type="date"
                  name="reception_date"
                  value={values.reception_date}
                  placeholder="YYYY-MM-DD"
                  style={{
                    width: '150px',
                    display: 'inline-block',
                    padding: '0.25rem 0.5rem',
                  }}
                  onChange={e => {
                    setFieldValue('reception_date', e.target.value)
                  }}
                />
                <input
                  type="time"
                  name="reception_time"
                  required
                  value={values.reception_time}
                  onChange={e => {
                    setFieldValue('reception_time', e.target.value)
                  }}
                  style={{
                    padding: '0.25rem 0.5rem',
                  }}
                />
              </label>
              <ErrorMessage
                name="reception_date"
                component="small"
                className="text-danger"
              />
              <ErrorMessage
                name="reception_time"
                component="small"
                className="text-danger"
              />
            </div>

            <div style={{ margin: '1rem 0' }}>
              <label>
                ESTADO DEL PACIENTE AL MOMENTO DE LA REMISIÓN
                <textarea
                  name="status"
                  style={{ width: '100%', padding: '0.5rem' }}
                  rows="6"
                  value={values.status}
                  onChange={e => setFieldValue('status', e.target.value)}
                  placeholder="Describe el estado del paciente..."
                />
              </label>
              <ErrorMessage
                name="status"
                component="small"
                className="text-danger"
              />
            </div>

            <div
              style={{
                display: 'flex',
                width: '320px',
                margin: '0 auto',
                justifyContent: 'space-between',
              }}
            >
              <button
                type="submit"
                style={{
                  color: 'white',
                  width: '100px',
                  display: 'block',
                  backgroundColor: '#1D1D1B',
                  borderColor: '#707070',
                  fontSize: '18px',
                  lineHeight: 1.8,
                  padding: 0,
                  fontWeight: 700,
                }}
              >
                GUARDAR
              </button>
              <button
                type="button"
                style={{
                  color: 'white',
                  width: '200px',
                  display: 'block',
                  backgroundColor: '#1D1D1B',
                  borderColor: '#707070',
                  fontSize: '18px',
                  lineHeight: 1.8,
                  padding: 0,
                  fontWeight: 700,
                }}
                onClick={() => {
                  onDeleteRemissionRequest()
                  onClose(false)
                }}
              >
                CANCELAR REMISIÓN
              </button>
            </div>
          </Form>
        )}
      />
    </Modal>
  )
}
