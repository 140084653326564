import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

const useReasons = defaultValue => {
  const [reasons, setReasons] = useState(defaultValue)

  useEffect(() => {
    GET('reasons').then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.body !== null) {
        setReasons(
          res.body.map(item => {
            return {
              value: item.id,
              label: item.name,
            }
          })
        )
      }
    })
  }, [])

  return [reasons, setReasons]
}

export default useReasons
