import React from 'react'
import Modal from 'react-modal'

import closeIcon from '../../images/close.svg'

export function RemissionPopup({ isOpen, onClose }) {
  Modal.setAppElement('.layout-container')

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { zIndex: 999 },
        content: {
          width: '700px',
          margin: '0 auto',
          padding: '4rem 4rem 2rem 4rem',
          height: '530px',
        },
      }}
    >
      <div
        onClick={() => onClose()}
        style={{
          cursor: 'pointer',
          width: '24px',
          height: '24px',
          backgroundImage: `url("${closeIcon}")`,
          backgroundSize: 'cover',
          position: 'absolute',
          top: '2rem',
          right: '2rem',
        }}
      />

      <div
        style={{
          margin: '0 auto',
          display: 'flex',
          flexWrap: 'wrap',
          fontSize: '18px',
        }}
      >
        INFORMAR AL CONSULTANTE: Teniendo en cuenta las excepciones de
        confidencialidad mencionadas al inicio de llamada, le informamos, que
        sus datos personales (nombre, CC, rango, ubicación, y teléfono de la
        persona de contacto), serán registrados en un formato habilitado para la
        remisión de su caso a la Dirección de Sanidad (Ejército, Fuerza aérea,
        Armada o Dirección de Familia Militar). En ese mismo formato se
        consignarán de manera precisa los aspectos relacionados con su motivo de
        consulta y/o los procesos de atención psicológica que usted recibió al
        solicitar apoyo a la Línea en Defensa de la Vida, se omitirán los datos
        que no autorice a consignar y que solo usted presentará al profesional
        al que sea remitido.
      </div>

      <h1
        style={{
          fontSize: '20px',
          margin: '1em auto',
          maxWidth: '400px',
          textAlign: 'center',
          fontWeight: 700,
          textTransform: 'uppercase',
        }}
      >
        ¿El paciente está de acuerdo con ser remitido?
      </h1>

      <div
        style={{
          display: 'flex',
          width: '220px',
          margin: '0 auto',
          justifyContent: 'space-between',
        }}
      >
        <button
          style={{
            color: 'white',
            width: '100px',
            display: 'block',
            backgroundColor: '#1D1D1B',
            borderColor: '#707070',
            fontSize: '18px',
            lineHeight: 1.8,
            padding: 0,
            fontWeight: 700,
          }}
          onClick={() => {
            if (onClose) onClose(true)
          }}
        >
          SÍ
        </button>
        <button
          style={{
            color: 'white',
            width: '100px',
            display: 'block',
            backgroundColor: '#1D1D1B',
            borderColor: '#707070',
            fontSize: '18px',
            lineHeight: 1.8,
            padding: 0,
            fontWeight: 700,
          }}
          onClick={() => {
            if (onClose) onClose(false)
          }}
        >
          NO
        </button>
      </div>
    </Modal>
  )
}
